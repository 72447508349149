<header>
  <div id="header-sticky"
       [ngClass]="headerSticky ? 'header__area header__transparent header__padding sticky' :
        'header__area bg-primary header__padding header__shadow' "
       class="{{header__white}}">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
          <div class="header__left d-flex">
            <div class="logo">
              <a [routerLink]="['/']">
                <img *ngIf="!headerSticky" src="assets/img/logo/logo-white.png" alt="logo"/><!-- white logo-->
                <img *ngIf="headerSticky" src="assets/img/logo/logo-blue.png" alt="logo"> <!--blue logo-->
              </a>
            </div>
          </div>
        </div>
        <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
          <div class="header__right d-flex justify-content-end align-items-center">
            <div class="main-menu" [class.main-menu-3]="header__white">
              <nav id="mobile-menu" class="d-none d-xl-block">
                <ul>
                  <li><a [routerLink]="['/']">Home</a></li>
                  <li class="has-dropdown">
                    <a [routerLink]="['/courses']">Courses</a>
                    <ul class="submenu">
                      <li><a [routerLink]="['/course-details/Price-Action']">Price Action</a></li>
                      <li><a [routerLink]="['/course-details/NEoWave-Analysis-Trading']">NEoWave Analysis</a></li>
                      <li><a [routerLink]="['/course-details/Advance-FnO']">Advance F&O</a></li>
                      <li><a [routerLink]="['/course-details/NRT-Trading']">NRT Trading</a></li>
                      <li><a [routerLink]="['/course-details/Fundamental-Analysis']">Fundamentals</a></li>
                      <li><a [routerLink]="['/course-details/Trading-Psychology']">Trading Psychology</a></li>
                    </ul>
                  </li>
                  <li class="has-dropdown">
                    <a [routerLink]="['/services']">Services</a>
                    <ul class="submenu">
                      <li><a [routerLink]="['/forecasting']">NEoWave Forecasting</a></li>
                      <li><a [routerLink]="['/plugin-wave']">Wave Chart Plugin</a></li>
                      <li><a [routerLink]="['/plugin-trading']">NRT Plugin</a></li>
                    </ul>
                  </li>
                  <li class="has-dropdown">
                    <a [routerLink]="['/resources']">Resources</a>
                    <ul class="submenu">
                      <li><a [routerLink]="['/forecast']">Forecast Charts</a></li>
                      <li><a [routerLink]="['/previous-forecasting']">Forecast Videos</a></li>
                      <li><a [routerLink]="['/podcast']">Podcast</a></li>
                      <!--<li><a [routerLink]="['/ask-questions']">Ask Questions</a></li>-->
                    </ul>
                  </li>
                  <!--<li><a [routerLink]="['/blogs']">Blogs</a></li>-->
                  <li><a [routerLink]="['/contact']">Contact</a></li>
                </ul>
              </nav>
            </div>
            <!--<div class="header__btn ml-20 d-none d-sm-block">
              <a [routerLink]="['/sign-in']" class="e-btn border-white-2 border-radius-0">Login</a>
            </div>-->
            <div class="sidebar__menu d-xl-none">
              <div (click)="handleSidebar()" class="sidebar-toggle-btn ml-30" id="sidebar-toggle">
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- sidebar area start -->
<div class="sidebar__area" [class.sidebar-opened]="showSidebar">
  <div class="sidebar__wrapper">
    <!--<div (click)="handleSidebarClose()" class="sidebar__close">
      <button class="sidebar__close-btn" id="sidebar__close-btn">
        <span><i class="icon_close text-20"></i></span>
        <span>close</span>
      </button>
    </div>-->
    <div class="sidebar__content">
      <div class="logo mb-40">
        <a (click)="handleSidebarClose()"  [routerLink]="['/']"><img src="assets/img/logo/logo-blue.png" alt="logo"></a>
      </div>

      <div class="side-info-content sidebar-menu mm-menu">
        <ul>
          <li><a (click)="handleSidebarClose()" [routerLink]="['/']">Home</a></li>

          <li class="menu-item-has-children has-droupdown" [class.active]="showCoursesDropdown">
            <a [routerLink]="['/courses']" (click)="coursesDropdown()">Courses</a>
            <ul class="sub-menu" [class.active]="showCoursesDropdown">
              <li><a (click)="handleSidebarClose()" [routerLink]="['/course-details/Price-Action']">Price Action</a></li>
              <li><a (click)="handleSidebarClose()" [routerLink]="['/course-details/NEoWave-Analysis-Trading']">NEoWave Analysis</a></li>
              <li><a (click)="handleSidebarClose()" [routerLink]="['/course-details/Advance-FnO']">Advance F&O</a></li>
              <li><a (click)="handleSidebarClose()" [routerLink]="['/course-details/NRT-Trading']">NRT Trading</a></li>
              <li><a (click)="handleSidebarClose()" [routerLink]="['/course-details/Fundamental-Analysis']">Fundamentals</a></li>
              <li><a (click)="handleSidebarClose()" [routerLink]="['/course-details/Trading-Psychology']">Trading Psychology</a></li>
            </ul>
          </li>

          <li class="menu-item-has-children has-droupdown" [class.active]="showBlogDropdown">
            <a [routerLink]="['/services']" (click)="blogDropdown()">Services</a>
            <ul class="sub-menu" [class.active]="showBlogDropdown">
              <li><a (click)="handleSidebarClose()" [routerLink]="['/forecasting']">NEoWave Forecasting</a></li>
              <li><a (click)="handleSidebarClose()" [routerLink]="['/plugin-wave']">Wave Chart Plugin</a></li>
              <li><a [routerLink]="['/plugin-trading']">NRT Plugin</a></li>
            </ul>
          </li>

          <li class="menu-item-has-children has-droupdown" [class.active]="showPagesDropdown">
            <a [routerLink]="['/resources']" (click)="pagesDropDown()">Resources</a>
            <ul class="sub-menu" [class.active]="showPagesDropdown">
              <li><a (click)="handleSidebarClose()" [routerLink]="['/forecast']">Forecast Charts</a></li>
              <li><a (click)="handleSidebarClose()" [routerLink]="['/previous-forecasting']">Forecast Videos</a></li>
              <li><a (click)="handleSidebarClose()" [routerLink]="['/podcast']">Podcast</a></li>
              <!--<li><a [routerLink]="['/ask-questions']">Ask Questions</a></li>-->
            </ul>
          </li>

          <li><a (click)="handleSidebarClose()" [routerLink]="['/contact']" class="border-0">Contact</a></li>
          <!--<li><a (click)="handleSidebarClose()" [routerLink]="['/sign-in']" class="border-0">Login</a></li>-->
          <!--<li><a [routerLink]="['/blogs']" class="border-0">Blogs</a></li>-->
        </ul>
      </div>

    </div>
  </div>
</div>

<div (click)="handleSidebarClose()" class="body-overlay"
     [class.opened]="showSidebar"></div>
<!-- sidebar area end -->
