import {Injectable, OnInit} from "@angular/core";

export interface ForecastingI {
  oldPrice?: string;
  priceMonth: string;
  priceTotal: string;
  period: string;
  payment: string;
  upi?: string;
  offer?:boolean;
  offerPrice?: string
}


@Injectable({
  providedIn: 'root'
})
export class ForecastingService implements OnInit {

  forecast: ForecastingI[] = [{
    oldPrice: "₹15,000.00",
    priceMonth: "₹15,000",
    priceTotal: "₹12,500",
    period: "1 Month",
    payment: "https://pages.razorpay.com/pl_KqzCAKat0NHJxG/view",
    // upi: "₹10,500.00"
  }, {
    oldPrice: "₹37,500.00",
    priceMonth: "₹12,500 / Month",
    priceTotal: "₹33,000",
    offerPrice: "₹7,260",
    period: "3 Month",
    offer: false,
    payment: "https://pages.razorpay.com/pl_Ol6zoMvkANAkT4/view", // https://pages.razorpay.com/pl_LCEPl4P4TrHS1E/view
    // upi: "₹30,000.00"
  }, {
    oldPrice: "₹66,000.00",
    priceMonth: "₹11,000 / Month",
    priceTotal: "₹63,000",
    period: "6 Month",
    payment: "https://pages.razorpay.com/pl_L0Ou2EMcw4xpZ8/view",
    // upi: "₹60,000.00"
  }, {
    oldPrice: "₹1,26,000.00",
    priceMonth: "₹10,500 / Month",
    priceTotal: "₹1,20,000",
    period: "12 Month",
    payment: "https://pages.razorpay.com/pl_LPmbhC8vQ3Ybue/view", // 75K https://pages.razorpay.com/pl_KyzNs8AVgepnJX/view
    // upi: "₹1,15,000.00"
  }]

  constructor() {
  }

  ngOnInit(): void {
  }

  public getForecasting(): ForecastingI[] {
    return this.forecast;
  }
}
