import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomeRoutingModule} from './home-routing.module';
import {SignInComponent} from './sign-in/sign-in.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {HomeIndexComponent} from './index/home-index.component';
import {RouterModule} from "@angular/router";
import {MatTabsModule} from "@angular/material/tabs";
import {SwiperModule} from "swiper/angular";
import {HeroComponent} from './common/hero/hero.component';
// import {CategoryComponent} from './common/category/category.component';
// import {BannerAreaComponent} from './common/banner-area/banner-area.component';
// import {EventsComponent} from './common/events/events.component';

// import {BlogMainComponent} from './resourses/blogs/blog-main/blog-main.component';
// import {BlogAreaComponent} from './resourses/blogs/blog-area/blog-area.component';
// import {BlogDetailsComponent} from './resourses/blogs/blog-details/blog-details.component';
// import {BlogSidebarComponent} from './resourses/blogs/blog-sidebar/blog-sidebar.component';

// import {AskAreaComponent} from './resourses/ask-questions/ask-area/ask-area.component';
// import {AskMainComponent} from "./resourses/ask-questions/ask-main/ask-main.component";
import {CourseDetailsComponent} from './courses/course-details/course-details.component';
import {CoursePageComponent} from './courses/course-page/course-page.component';
import {CoursePageAreaComponent} from './courses/course-page-area/course-page-area.component';
import {BreadcrumbComponent} from "./common/breadcrumb/breadcrumb.component";
import {DisclosureComponent} from "./common/disclosure/disclosure.component";
import {ErrorPageComponent} from "./common/error-page/error-page.component";
import {HeaderComponent} from "./common/header/header.component";
import {FooterComponent} from "./common/footer/footer.component";
import {PrivacyPolicyComponent} from "./common/privacy-policy/privacy-policy.component";
import {SecurityComponent} from "./common/security/security.component";
import {TermsOfServicesComponent} from "./common/terms-of-services/terms-of-services.component";
import {MainAreaComponent} from './main-area/main-area.component';
import {ServiceMainComponent} from './services/service-main/service-main.component';
import {AboutUsComponent} from './common/about-us/about-us.component';
import {ContactUsComponent} from './common/contact-us/contact-us.component';
import {ServiceAreaComponent} from './services/service-area/service-area.component';
import {FaqsMainComponent} from './faqs/faqs-main/faqs-main.component';
import {FaqsAreaComponent} from './faqs/faqs-area/faqs-area.component';
import {ForecastMainComponent} from './resourses/forecast/forecast-main/forecast-main.component';
import {ForecastDetailsComponent} from './resourses/forecast/forecast-details/forecast-details.component';
import {PodcastMainComponent} from './resourses/podcast/podcast-main/podcast-main.component';
import {PodcastDetailsComponent} from './resourses/podcast/podcast-details/podcast-details.component';
import {PaginationComponent} from "./common/pagination/pagination.component";
import {RelatedCourseComponent} from "./courses/related-course/related-course.component";
import {CoursePricingComponent} from './courses/course-pricing/course-pricing.component';
import {ForecastingComponent} from './services/forecasting/forecasting.component';
import {PluginWaveComponent} from './services/plugin-wave/plugin-wave.component';
import {PluginFnoComponent} from './services/plugin-fno/plugin-fno.component';
import {ResourceMainComponent} from './resourses/resource-main/resource-main.component';
import {ResourseAreaComponent} from './resourses/resourse-area/resourse-area.component';
import {ForecastingVideosComponent} from './services/forecasting-videos/forecasting-videos.component';
import {Course, ForecastingService, ForecastVideos, MetaService} from "../../shared";
import { OldForecastComponent } from './resourses/old-forecast/old-forecast.component';


@NgModule({
  declarations: [
    SignInComponent,
    SignUpComponent,
    HomeIndexComponent,
    HeroComponent,
    CourseDetailsComponent,
    CoursePageComponent,
    CoursePageAreaComponent,
    BreadcrumbComponent,
    DisclosureComponent,
    ErrorPageComponent,
    HeaderComponent,
    FooterComponent,
    HeroComponent,
    PrivacyPolicyComponent,
    SecurityComponent,
    TermsOfServicesComponent,
    MainAreaComponent,
    ServiceMainComponent,
    AboutUsComponent,
    ContactUsComponent,
    ServiceAreaComponent,
    FaqsMainComponent,
    FaqsAreaComponent,
    ForecastMainComponent,
    ForecastDetailsComponent,
    PodcastMainComponent,
    PodcastDetailsComponent,
    PaginationComponent,
    RelatedCourseComponent,
    CoursePricingComponent,
    ForecastingComponent,
    PluginWaveComponent,
    PluginFnoComponent,
    ResourceMainComponent,
    ResourseAreaComponent,
    ForecastingVideosComponent,
    OldForecastComponent,
    // BannerAreaComponent,
    // CategoryComponent,
    // EventsComponent,
    // BlogMainComponent,
    // BlogAreaComponent,
    // BlogDetailsComponent,
    // BlogSidebarComponent,
    // AskMainComponent,
    // AskAreaComponent,
  ],
  providers: [Course, ForecastingService, ForecastVideos, MetaService],
  imports: [
    CommonModule,
    HomeRoutingModule,
    RouterModule,
    MatTabsModule,
    SwiperModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule {
}
