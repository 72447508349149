import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {CourseI} from "../../../../shared/constants/course";

@Component({
  selector: 'app-course-pricing',
  templateUrl: './course-pricing.component.html',
  styleUrls: ['./course-pricing.component.scss']
})
export class CoursePricingComponent implements OnInit, AfterViewInit {

  @Input() course: CourseI | undefined;

  constructor(private sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  public getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
