import { Component } from '@angular/core';

@Component({
  selector: 'app-old-forecast',
  templateUrl: './old-forecast.component.html',
  styleUrls: ['./old-forecast.component.scss']
})
export class OldForecastComponent {

}
