<div [ngClass]="white_bg ? white_bg : 'course__sidebar-widget grey-bg'">
  <div class="course__sidebar-course">
    <!--<h3 class="course__sidebar-title">Other courses</h3>-->
    <div *ngFor="let course of courses">
      <div class="row course__sm bg-primary d-flex align-items-center mb-20">
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 p-5">
          <div class="card bg-primary m-0 p-0 border-radius-0 border-0">
            <div class="card-body m-0 p-0">
              <img class="card-img border-radius-0" [routerLink]="'/course-details/'+[course.uri]" alt="{{course.title}}" src="{{course.img}}"/>
              <p class="text-white font-weight-800 text-18 pl-5 pt-10"><a [routerLink]="['/course-details/'+ course.uri]">{{course.title}}</a>
              </p>
              <p class="text-gray-300 pl-5">{{course.description}}</p>
              <div class="d-flex justify-content-end align-items-end">
                <div class="bg-primary pr-5">
                  <a [routerLink]="['/course-details/'+ course.uri]" class="text-white">Know Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


