import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeIndexComponent} from "./views/home/index/home-index.component";
import {BlankLayoutComponent} from "./shared/component/blank-layout/blank-layout.component";

const routes: Routes = [
  {
    path: '',
    component: HomeIndexComponent,
    children: [{
      path: '',
      loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
    }]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [{
      path: 'others',
      loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
    }]
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
