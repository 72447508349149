import {Component} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Location} from '@angular/common';

declare const gtag: Function;
import {filter} from 'rxjs/operators';
import {MetaService} from "./shared";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'intellxis-new-web';
  listUrl = [
    '/security',
    '/services',
    '/disclosure',
    '/privacy-policy',
    '/terms-of-service',
    '/contact',
    '/about',
    '/resources',
    '/sign-in',

    '/plugin-trading',
    '/plugin-wave',
    '/previous-forecasting',
    '/forecast',
    '/podcast',
    '/forecasting',
    '/faqs',
    '/blogs',
    '/blog-details/1',
    '/blog-details/2',
    '/blog-details/3',
    '/blog-details/4',
    '/blog-details/5',
    '/blog-details/6',

    '/courses',
    '/course-details/1', // Price Action
    '/course-details/2', // Fundamentals
    '/course-details/3', // Advance F&O
    '/course-details/4', // Wave Analysis
    '/course-details/5', // F&O Trading
    '/course-details/6', // Trading Psychology
    '/course-details/Price-Action',
    '/course-details/Fundamental-Analysis',
    '/course-details/Advance-FnO',
    '/course-details/NEoWave-Analysis-Trading',
    '/course-details/NRT-Trading',
    '/course-details/Trading-Psychology'
  ];

  constructor(
    private location: Location,
    private router: Router,
    private readonly metaService: MetaService,
  ) {

    // console.log(this.listUrl, this.location.path())

    if (!this.listUrl.includes(this.location.path())) {
      this.router.navigateByUrl('/');
    }

    /**
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.metaService.updateMetaTags(this.location.path());
      let url = event?.urlAfterRedirects.toString().split('/');
      // console.log({
      //     page_title: url[url.length -1],
      //     page_path: event.urlAfterRedirects,
      //     page_location: event.urlAfterRedirects,
      // });
      gtag('event', 'page_view', {
        page_title: url[url.length - 1],
        page_path: event.urlAfterRedirects,
        page_location: event?.urlAfterRedirects,
      });
    });
    */

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      const navigationEndEvent = event as NavigationEnd; // Type assertion
      let title = this.metaService.updateMetaTags(this.location.path());
      let url = navigationEndEvent.urlAfterRedirects.toString().split('/');
      // console.log(({
      //   path: this.location.path(),
      //   page_title: title,
      //   page_path: navigationEndEvent.urlAfterRedirects,
      //   page_location: navigationEndEvent.urlAfterRedirects,
      // }))
      gtag('event', 'page_view', {
        page_title: title,
        page_path: navigationEndEvent.urlAfterRedirects,
        page_location: navigationEndEvent.urlAfterRedirects,
      });
    });

  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return
      }
      window.scrollTo(0, 0)
    })
  }

}
