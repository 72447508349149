import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!!window && !!window.scrollTo) {
        // window.scrollTo(0, 0);
        let doc = document.querySelector('#privacyPolicy');
        if(doc) {
          doc.scrollIntoView({behavior: 'smooth'});
          window.scrollTo(doc.scrollLeft, doc.scrollTop - 200);
          // doc.scrollBy(doc.scrollLeft, doc.scrollTop - 200);
        }
      }
    }, 500);
  }

}
