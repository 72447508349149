import { Component } from '@angular/core';

@Component({
  selector: 'app-resourse-area',
  templateUrl: './resourse-area.component.html',
  styleUrls: ['./resourse-area.component.scss']
})
export class ResourseAreaComponent {

}
