<section class="course__area pb-50">
  <div class="container mt-50">
    <div class="row align-items-center">
      <div class="col-xxl-12 col-xl-12 col-lg-12 text-center">
        <div class="section__title-wrapper">
          <h1 class="section__title text-primary"><span class="yellow-bg yellow-bg-big">Courses<img
            src="assets/img/shape/yellow-bg.png" alt=""></span> for you</h1>
          <h2>Begin Your Journey</h2>
          <h3 class="mt-15 font-weight-500 pb-0 mb-0">Embark on Your Learning Journey with Our Comprehensive Basic to
            Advanced and Mentorship Programs</h3>
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="course_wrapper">
        <div class="row mt-30">
          <div *ngFor="let course of courses" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <div class="course__item white-bg mb-30 fix shadow-sm" style="background-color: #D5E8FB;">
              <div class="course__thumb w-img p-relative fix">
                <a [routerLink]="'/course-details/'+[course.uri]">
                  <img src="{{course.img}}" alt="">
                  <div class="course__tag">
                    <a [routerLink]="'/course-details/'+[course.uri]" >{{course.category}}</a><!--[class]="course.color"-->
                  </div>
                </a>

              </div>
              <div class="course__content mb-0 pb-0">
                <div class="course__meta">
                  <div class="course__lesson">
                    <span class="text-black text-16"><i class="icon_book_alt"></i>{{course.sessions}} Live Sessions </span>
                  </div>
                </div>
                <h4 class="course__title"><a [routerLink]="'/course-details/'+[course.uri]">{{course.title}}</a></h4>
                <p class="text-black">{{course.description}}</p>
                <h5 class="animate__animated animate__infinite" [ngClass]="{'animate__pulseOut': course.newBatch.flag}">New Batch: {{ course.newBatch.flag ? course.newBatch.startDate : "Start Soon"}}</h5>
              </div>
              <div class="course__more d-flex justify-content-end align-items-end mt-0">
                <div class="course__btn">
                  <a [routerLink]="'/course-details/'+[course.uri]" class="link-btn">
                    Know Details
                    <i class="arrow_carrot-2right text-18"></i>
                    <i class="arrow_carrot-2right text-18"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
