<!--<section class="hero__area hero__height hero__height-2 d-flex align-items-center blue-bg-3 p-relative fix"-->
<!--         style="background-image: url('assets/img/hero/home-banner-waveanalysis.jpg');background-repeat: no-repeat;background-size: cover;background-position: center">-->
<!--  <div class="container align-items-center m-0 p-0">-->
<!--    <a style="position: absolute;bottom: 40px;right: 40px;" [routerLink]="['/courses']"-->
<!--       class="_animate_pulse_black border-radius-0 text-25 pl-20 pr-20 btn btn-primary float-end">Register Now</a>-->
<!--  </div>-->
<!--</section>-->

<section class="slider__area  p-relative bg-primary">
  <div class="slider__wrapper swiper-container">
    <swiper class="swiper-wrapper" [slidesPerView]="1" [loop]="true"
            [effect]="'fade'"
            [autoplay]="{delay: 5000,disableOnInteraction: false}">

      <!--<ng-template swiperSlide>
        <div class="single-slider swiper-slide slider__height d-flex align-items-center"
             [ngStyle]="{background:'url(assets/img/hero/home-banner-offer.png)',backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}">
          <a style="position: absolute;bottom: 50px;right: 80px;" [routerLink]="['/courses']"
             class="border-radius-0 text-25 pl-20 pr-20 _animate_pulse_black btn animate__animated animate__infinite animate__pulseIn btn-secondary text-white float-end">Register
            Now</a>
        </div>
      </ng-template>-->

      <ng-template swiperSlide>
        <div class="single-slider swiper-slide slider__height d-flex align-items-center"
             [ngStyle]="{background:'url(assets/img/hero/home-banner-waveanalysis.jpg)',backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}">
          <!-- <a style="position: absolute;bottom: 50px;right: 80px;" [routerLink]="['/course-details/4']" class="border-radius-0 text-25 pl-20 pr-20 _animate_pulse_black btn animate__animated animate__infinite animate__pulseIn btn-warning text-white float-end">Register Now</a> -->
        </div>
      </ng-template>

     <ng-template swiperSlide>
        <div class="single-slider swiper-slide slider__height d-flex align-items-center" [ngStyle]="{background:'url(assets/img/hero/home-banner-fnotrading.jpg)',backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}">
         <!-- <a style="position: absolute;bottom: 50px;right: 80px;" [routerLink]="['/course-details/5']" class="border-radius-0 text-25 pl-20 pr-20 _animate_pulse_black btn animate__animated animate__infinite animate__pulseIn btn-success text-white float-end">Register Now</a> -->
        </div>
      </ng-template>

      <ng-template swiperSlide>
        <div class="single-slider swiper-slide slider__height d-flex align-items-center"
             [ngStyle]="{background:'url(assets/img/hero/home-banner-psychology.jpg)',backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}">
          <!-- <a style="position: absolute;bottom: 50px;right: 80px;" [routerLink]="['/course-details/6']" class="border-radius-0 text-25 pl-20 pr-20 _animate_pulse_black btn animate__animated animate__infinite animate__pulseIn btn-info text-white float-end">Register Now</a> -->
        </div>
      </ng-template>

    </swiper>
  </div>

  <div class="row g-0">
    <div *ngFor="let item of sliderNavData" class="d-none d-md-block col-md-6 col-lg-3">
      <a class="slider__nav-item swiper-slide" [class]="item.bgColor" [routerLink]="item.link">
        <div class="slider__nav-content">
          <span>{{item.title}}</span>
          <h4>{{item.subtitle}}</h4>
          <!--<p class="text-white">{{item.description}}</p>-->
        </div>
      </a>
    </div>
  </div>

</section>

<!--divider -->
<!--<div style="width: 100% !important;" class="custom-shape-divider-top bg-transparent">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 491.58" preserveAspectRatio="none">
    <g style="isolation:isolate">
      <path class="shape-divider-fill" d="M1000 479.4v-87.96L500 0 0 391.46v87.96l500-335.94 500 335.92z"
            opacity="0.12" mix-blend-mode="overlay"/>
      <path class="shape-divider-fill"
            d="M1000 487.31v-7.91L500 143.48 0 479.42v7.91l500-297.96 500 297.94z" opacity="0.25"
            mix-blend-mode="overlay"/>
      <path class="shape-divider-fill" d="M1000 487.31L500 189.37 0 487.33v4.25h1000v-4.27z"/>
    </g>
  </svg>
</div>-->

<section class="hero__area d-flex align-items-center bg-primary p-relative fix">
  <!--<div class="container">-->
  <div class="row align-items-center bg-primary">
    <div class="col-1"></div>
    <div class="col-lg-5 p-30" style="text-align: justify-all">
      <h1 class="text-white">Unlock the World of Online Stock Trading in India with Intellxis</h1>
      <p class="text-gray-400 text-22">
        Discover a comprehensive array of professional stock market courses online designed to empower you with the
        knowledge and skills essential for successful investing. At Intellxis, we are committed to unraveling the
        complexities of stock market trading, offering meticulously crafted courses encompassing key aspects such as
        risk management, technical and fundamental analyses, futures & options, intraday and delivery trading,
        commodities, mutual funds, and portfolio management.
      </p>
      <p class="text-gray-400 text-22">
        Navigate through our specialized courses, including the Comprehensive Trading Course, In-Depth Investing Course,
        and Options Trading Mastery, tailored to enhance your potential in the dynamic world of stock trading. Every
        year, numerous individuals step into the stock market, but a lack of foundational skills often hinders their
        success. At Intellxis, we provide you with the expertise necessary to tilt the odds in your favor, making us
        your one-stop hub for stock market education.
      </p>
    </div>

    <div class="col-lg-6">
      <div class="about-img-2">
        <img width="100%" class="animate__animated animate__pulseIn animate__infinite"
             src="assets/img/home/completepackage.png" alt="About Images">
      </div>
    </div>
  </div>
  <!--  </div>-->
</section>

<!--divider -->
<div style="transform: rotate(180deg); margin-top: -1px" class="custom-shape-divider-bottom">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 491.58" preserveAspectRatio="none">
    <g style="isolation:isolate">
      <path class="shape-divider-fill" d="M1000 479.4v-87.96L500 0 0 391.46v87.96l500-335.94 500 335.92z"
            opacity="0.12" mix-blend-mode="overlay"/>
      <path class="shape-divider-fill"
            d="M1000 487.31v-7.91L500 143.48 0 479.42v7.91l500-297.96 500 297.94z" opacity="0.25"
            mix-blend-mode="overlay"/>
      <path class="shape-divider-fill" d="M1000 487.31L500 189.37 0 487.33v4.25h1000v-4.27z"/>
    </g>
  </svg>
</div>

<section class="course__area pb-50 bg-gray pt-150" style="margin-top: -150px !important;">
  <div class="container mt-50">
    <div class="row align-items-center bg-gray">
      <div class="col-xxl-12 col-xl-12 col-lg-12">
        <div class="section__title-wrapper text-center">
          <h2 class="section__title text-primary">Why to join <span class="yellow-bg yellow-bg-big">Intellxis<img
            src="assets/img/shape/yellow-bg.png" alt=""></span></h2>
          <!--<h3>Improve Your Skills</h3>
          <h4 class="mt-15 font-weight-500 pb-0 mb-0">Embark on Your Learning Journey with Our Comprehensive Basic to
            Advanced and Mentorship Programs</h4>-->
        </div>
        <div class="course__description-list mt-25 text-align-justify">
          <ul>
            <li class="text-20 pb-30">
              <b class="text-primary">Expertise: </b> Our team consists of seasoned professionals with years of experience in the stock market. By joining Intellxis, you gain access to their expertise and insights, helping you navigate the complexities of stock trading with confidence.
            </li>
            <li class="text-20 pb-30">
              <b class="text-primary">Holistic Approach: </b> We believe in offering a holistic approach to stock market education. Our courses cover essential aspects such as risk management, technical and fundamental analyses, futures & options, intraday and delivery trading, commodities, mutual funds, and portfolio management, ensuring you have a well-rounded understanding of the market.
            </li>
            <li class="text-20 pb-30">
              <b class="text-primary">Tailored Learning: </b> Whether you're interested in mastering the art of day trading or building a long-term investment portfolio, Intellxis has courses tailored to suit your specific goals and preferences. Our specialized courses, including the Comprehensive Trading Course, In-Depth Investing Course, and Options Trading Mastery, provide you with the knowledge and skills necessary to succeed in your chosen area of focus.
            </li>
            <li class="text-20 pb-30">
              <b class="text-primary">Empowerment: </b> We are committed to empowering our students with the knowledge and skills they need to thrive in the stock market. By enrolling in Intellxis courses, you equip yourself with the tools and resources to make informed decisions and maximize your potential as a trader or investor.
            </li>
            <li class="text-20">
              <b class="text-primary">One-Stop Hub: </b> Intellxis serves as your one-stop hub for all your stock market education needs. Instead of piecing together information from various sources, you can rely on us to provide comprehensive and reliable training that lays a solid foundation for your journey in the stock market.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
