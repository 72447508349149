<main>
  <app-breadcrumb title="Services" subtitle="Services" backImage="assets/img/page-title/blog.jpg"></app-breadcrumb>
  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:375px;color: transparent"
     class="icon_check _animate_pulse"></i>
  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:375px;color: transparent"
     class="icon_check _animate_pulse-3"></i>
  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:375px;color: transparent"
     class="icon_check _animate_pulse-5"></i>
  <app-service-area></app-service-area>
</main>
