import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

// import Swiper core and required modules
import SwiperCore, {Autoplay, Pagination} from "swiper";
import {core} from "@angular/compiler";
import {Course, CourseI} from "../../../../shared/constants/course";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay])

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit {
  @Input() id: string | null = "";

  courses: CourseI[] = [];

  course: CourseI | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private _courses: Course
  ) {
    this.courses = this._courses.getCourses();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.course = this._courses.getCourses().find((cr: CourseI) => cr.id.toString() == this.id || cr.uri == this.id);
      this.cdRef.detectChanges();  // Manually check for changes
    });
  }

  getNewList(): CourseI[] {
    return this._courses.getCourses().filter((cr: CourseI) => cr.id.toString() != this.id || cr.uri != this.id);
  }

  protected readonly core = core;
}
