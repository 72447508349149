<main>
  <app-breadcrumb title="Security" subtitle="Security"
                  backImage="assets/img/page-title/security.jpg"></app-breadcrumb>

<!--  <i
    style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
    class="fal fa-check _animate_pulse"></i>
  <i
    style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
    class="fal fa-check _animate_pulse-3"></i>
  <i
    style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
    class="fal fa-check _animate_pulse-5"></i>-->

  <section class="pt-30 pb-50">
    <div class="container">
      <p class="text-primary mb-3 text-18 pl-2">
        The purpose of this Security Policy is to establish guidelines and best practices to ensure the confidentiality,
        integrity, and availability of Intellxis' systems, data, and resources. This policy outlines responsibilities,
        procedures, and measures to protect against unauthorized access, data breaches, and other security threats.
      </p>
      <ul class="text-primary mt-15 text-justify text-18">
        <li><i class="icon_check"></i> Users will be required to use strong, unique passwords and two-factor authentication for accessing systems and data.</li>
        <li><i class="icon_check"></i> Access rights will be assigned based on the principle of least privilege, ensuring that users have access only to the resources necessary for their roles.</li>
      </ul>
      <h3 class="border-bottom pt-15 pb-10">Security Policy Review and Updates</h3>
      <div class="text-primary mb-3 text-18 pl-2">
        This Security Policy will be reviewed and updated regularly to adapt to evolving threats and technologies.
      </div>

    </div>
  </section>
</main>
