import {Component, OnInit, Input} from '@angular/core';
import {CourseI} from "../../../../shared/constants/course";

@Component({
  selector: 'app-related-course',
  templateUrl: './related-course.component.html',
  styleUrls: ['./related-course.component.scss']
})
export class RelatedCourseComponent implements OnInit {

  @Input() white_bg: string | undefined;

  @Input() courses: CourseI[] | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

}
