<main class="{{bgColor}}">
  <div class="container pb-50">
    <div class="row align-items-center pt-50">
      <div class="col-xxl-12 col-xl-12 col-lg-12 text-center">
        <div class="section__title-wrapper">
          <h2 class="section__title text-primary">Recent <span class="yellow-bg yellow-bg-big">Forecasts<img
            src="assets/img/shape/yellow-bg.png" alt=""></span></h2>
          <h3>Improve Your Forecasting Skills</h3>
          <!--<h4 class="mt-15 font-weight-500 pb-0 mb-0">Embark on Your Learning Journey with Our Comprehensive Basic to Advanced and Mentorship Programs</h4>-->
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="course_wrapper">
        <div class="row mt-30">
          <h2 class="course__title pb-20">Long-Term Future Trends of the Individual Sector</h2>
          <div *ngFor="let link of youtube.indices" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <div class="course__item white-bg mb-30 fix shadow-sm card" style="background-color: #D5E8FB;">
              <a class="p-0 m-0"
                 [href]="getSafeUrl('https://www.youtube.com/watch?v='+link)" target="_blank"
                 data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall"
                 data-hover-speed="350" data-hover-parent=".portfolio-item"
                 data-lightbox="image" title="Image">
                <img class="card-img m-0 p-0 bg-primary"
                     [src]="getSafeUrl('https://i.ytimg.com/vi/'+link+'/mqdefault.jpg')" alt="youtube link">
                <i style="position: absolute;top: 45%;left: 45%;"
                   class="play-btn arrow_triangle-right text-25"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="course_wrapper">
        <div class="row mt-30">
          <h2 class="course__title pb-20">NIFTY Update | Educational Forecasting</h2>
          <div *ngFor="let link of youtube.nifty" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <div class="course__item white-bg mb-30 fix shadow-sm card" style="background-color: #D5E8FB;">
              <a class="p-0 m-0"
                 [href]="getSafeUrl('https://www.youtube.com/watch?v='+link)" target="_blank"
                 data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall"
                 data-hover-speed="350" data-hover-parent=".portfolio-item"
                 data-lightbox="image" title="Image">
                <img class="card-img m-0 p-0 bg-primary"
                     [src]="getSafeUrl('https://i.ytimg.com/vi/'+link+'/mqdefault.jpg')" alt="youtube link">
                <i style="position: absolute;top: 45%;left: 45%;"
                   class="play-btn arrow_triangle-right text-25"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="course_wrapper">
        <div class="row mt-30">
          <h2 class="course__title pb-20">Weekly Market Update | 2024 | Educational Forecasting</h2>
          <div *ngFor="let link of youtube.y2024" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <div class="course__item white-bg mb-30 fix shadow-sm card" style="background-color: #D5E8FB;">
              <a class="p-0 m-0"
                 [href]="getSafeUrl('https://www.youtube.com/watch?v='+link)" target="_blank"
                 data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall"
                 data-hover-speed="350" data-hover-parent=".portfolio-item"
                 data-lightbox="image" title="Image">
                <img class="card-img m-0 p-0 bg-primary"
                     [src]="getSafeUrl('https://i.ytimg.com/vi/'+link+'/mqdefault.jpg')" alt="youtube link">
                <i style="position: absolute;top: 45%;left: 45%;"
                   class="play-btn arrow_triangle-right text-25"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="course_wrapper">
        <div class="row mt-30">
          <h2 class="course__title pb-20">Weekly Market Update | 2023 | Educational Forecasting</h2>
          <div *ngFor="let link of youtube.y2023" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <div class="course__item white-bg mb-30 fix shadow-sm card" style="background-color: #D5E8FB;">
              <a class="p-0 m-0"
                 [href]="getSafeUrl('https://www.youtube.com/watch?v='+link)" target="_blank"
                 data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall"
                 data-hover-speed="350" data-hover-parent=".portfolio-item"
                 data-lightbox="image" title="Image">
                <img class="card-img m-0 p-0 bg-primary"
                     [src]="getSafeUrl('https://i.ytimg.com/vi/'+link+'/mqdefault.jpg')" alt="youtube link">
                <i style="position: absolute;top: 45%;left: 45%;"
                   class="play-btn arrow_triangle-right text-25"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="course_wrapper">
        <div class="row mt-30">
          <h2 class="course__title pb-20">Weekly Market Update | 2022 | Educational Forecasting</h2>
          <div *ngFor="let link of youtube.y2022" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <div class="course__item white-bg mb-30 fix shadow-sm card" style="background-color: #D5E8FB;">
              <a class="p-0 m-0"
                 [href]="getSafeUrl('https://www.youtube.com/watch?v='+link)" target="_blank"
                 data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall"
                 data-hover-speed="350" data-hover-parent=".portfolio-item"
                 data-lightbox="image" title="Image">
                <img class="card-img m-0 p-0 bg-primary"
                     [src]="getSafeUrl('https://i.ytimg.com/vi/'+link+'/mqdefault.jpg')" alt="youtube link">
                <i style="position: absolute;top: 45%;left: 45%;"
                   class="play-btn arrow_triangle-right text-25"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="course_wrapper">
        <div class="row mt-30">
          <h2 class="course__title pb-20">Weekly Market Update | 2021 | Educational Forecasting</h2>
          <div *ngFor="let link of youtube.y2021" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <div class="course__item white-bg mb-30 fix shadow-sm card" style="background-color: #D5E8FB;">
              <a class="p-0 m-0"
                 [href]="getSafeUrl('https://www.youtube.com/watch?v='+link)" target="_blank"
                 data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall"
                 data-hover-speed="350" data-hover-parent=".portfolio-item"
                 data-lightbox="image" title="Image">
                <img class="card-img m-0 p-0 bg-primary"
                     [src]="getSafeUrl('https://i.ytimg.com/vi/'+link+'/mqdefault.jpg')" alt="youtube link">
                <i style="position: absolute;top: 45%;left: 45%;"
                   class="play-btn arrow_triangle-right text-25"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>
