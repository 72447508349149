import {Injectable, OnInit} from "@angular/core";

export interface YoutubeLinks {
  nifty: string[];
  broadcast?: string[];
  y2021?: string[];
  y2022?: string[];
  y2023?: string[];
  y2024?: string[];
  indices: string[];
  stocks: string[];
}

@Injectable({
  providedIn: 'root'
})
export class ForecastVideos implements OnInit {
  youtube: YoutubeLinks = {
    nifty: [
      'flDjTXTFuf8',
      'bVTyhc7pCvo',
      '6z7RXo3IYls',
      'GYpGDFHh64A',
      '6EA0ZD1zvOw'
    ],

    y2021: [
      'O17Pe_TqxUY',
      'nk0En3P-tV4',
      'uAA7MpRogSo',
      'LTYwSnOpXEg',
      ],

    y2022: [
      'O17Pe_TqxUY',
      'nk0En3P-tV4',
      'uAA7MpRogSo',
      'LTYwSnOpXEg',
      ],

    y2023: [
      'XRzXDrKjCT8',
      '8Y5PPBl8gF4',
      '_OAqClBM7Tk',
      'JruC0NW1nhY',
      'iZeg2grtfpM',
      'yEjGoVjEl7I',
      'TLwZhe0-2RI',
      'XRzXDrKjCT8',
      'ngvd56xgqNA',
      '5KGmiFlMeJA',
    ],
    y2024: [
      'fGXKw7zm9Ts',
      'VPdn7qfzOPM',
      'iy9ubBs1mJQ',
      'lkBe4ne0YeQ',
      '4QDhUZY6JVo',
      'I8KhaUlNnpU',
      'qV_Xz-6BRMs',
      'abmaZB59SDc',
      '8YP0_ljo6Dg',
    ],
    indices: [
      'nCOg0Ns0RtA',
      'bXkGEiNIaaA',
      'gBFV-VWkUHI',
      'L5f8ph0nxmM',
      'YFuhJppnD78',
      'tsxSb8V7kk8',
      'IXv_g7LJ_4w',
      '9zn1hNZnU0s'
    ],

    stocks: [
      'DWZWHDQ2n90'
    ],
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  public getVideos(): YoutubeLinks {
    return this.youtube;
  }

}
