import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-plugin-fno',
  templateUrl: './plugin-fno.component.html',
  styleUrls: ['./plugin-fno.component.scss']
})
export class PluginFnoComponent implements OnInit {
  plugin = [{
    oldPrice: "₹15,000",
    price: "₹9,999",
    period: "1 Month",
    payment: "", // https://pages.razorpay.com/pl_LQA6jdovO7zl83/view
    upi: "intellxis@hdfcbank,INTELLXIS LLP",
    msg: "* You can subscribe for one month trial version only once."
  }, {
    oldPrice: "₹48000",
    price: "₹30,000",
    period: "6 Month",
    payment: "", //https://pages.razorpay.com/pl_LQA5uazR3nUn4r/view
    upi: "intellxis@hdfcbank,INTELLXIS LLP",
    msg: "Subscribe our premium version of NRT plugin for 6 Months."
  }, {
    oldPrice: "₹96000",
    price: "₹40,000",
    period: "12 Month",
    payment: "", // https://pages.razorpay.com/pl_KPwrH2FYlPag2o/view
    upi: "intellxis@hdfcbank,INTELLXIS LLP",
    msg: "Subscribe our premium version of NRT plugin for 12 Months."
  }];

  constructor(private sanitizer: DomSanitizer, public changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  public getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
