<section class="page__title-area pt-20 pb-30">
  <!--<div class="page__title-shape">
    <img class="page-title-shape-5 d-none d-sm-block _animate_dot_updown" src="assets/img/page-title/page-title-shape-1.png" alt=""/>
    <img class="page-title-shape-6 _animate_dot_down" src="assets/img/page-title/page-title-shape-6.png" alt=""/>
    <img class="page-title-shape-7 _animate_dot_down2" src="assets/img/page-title/page-title-shape-4.png" alt=""/>
  </div>-->
  <div class="container">
    <div class="row">
      <div class="col-xxl-9 col-xl-9 col-lg-9">
        <!--<div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">-->
        <div class="course__wrapper">
          <div class="page__title-content mb-25">
            <div class="page__title-breadcrumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                  <li class="breadcrumb-item"><a [routerLink]="['/courses']">Courses</a></li>
                  <li class="breadcrumb-item active" aria-current="page">{{course?.title}}</li>
                </ol>
              </nav>
            </div>
            <span class="page__title-pre bg-primary">{{course?.category}}</span>
            <h2 class="page__title-3">{{course?.title}}</h2>
            <!--<h2 class="page__title-5">{{course?.subtitle}}</h2>-->
            <p>{{course?.description}}</p>
          </div>
          <div class="course__img w-img mb-30">
            <img src="{{course?.img}}" alt=""/>
          </div>
          <div class="course__tab-2 mb-45">
            <ul class="nav nav-tabs" id="courseTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description"
                        type="button" role="tab" aria-controls="description" aria-selected="true">
                  <i class="icon_ribbon_alt"></i> <span>Description</span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum"
                        type="button" role="tab" aria-controls="curriculum" aria-selected="false">
                  <i class="icon_book_alt"></i> <span>Curriculum</span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review"
                        type="button" role="tab" aria-controls="review" aria-selected="false">
                  <i class="icon_star_alt"></i> <span>Testimonials</span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="faqs-tab" data-bs-toggle="tab" data-bs-target="#faqs"
                        type="button" role="tab" aria-controls="faqs" aria-selected="false">
                  <i class="icon_question"></i> <span>FAQs</span>
                </button>
              </li>
            </ul>
          </div>
          <div class="course__tab-content">
            <div class="tab-content" id="courseTabContent">
              <!-- start description tag-->
              <div class="tab-pane fade show active"
                   id="description" role="tabpanel" aria-labelledby="description-tab">
                <div class="course__description">
                  <h3>Course Overview</h3>
                  <p class="text-left" [innerHTML]="course?.details"></p>
                </div>
              </div>
              <!-- end description tag-->

              <!-- start faqs tag-->
              <div class="tab-pane fade show"
                   id="faqs" role="tabpanel" aria-labelledby="faqs-tab">
                <div class="course__description">
                  <div *ngFor="let ques of course?.courseDelFaqs" class="course__description-list mt-25">
                    <h3>{{ques.que}}</h3>
                    <ul *ngIf="ques.list && ques.list.length>0">
                      <li *ngFor="let q of ques.list">
                        <i class="icon_check"></i> <span [innerHTML]="q"></span>
                      </li>
                    </ul>
                    <p *ngIf="ques.ans" [innerHTML]="ques.ans"></p>
                  </div>
                </div>
              </div>
              <!-- end faqs tag-->

              <!-- start curriculum tag-->
              <div class="tab-pane fade" id="curriculum"
                   role="tabpanel" aria-labelledby="curriculum-tab">
                <div class="course__curriculum">
                  <div class="course__description">
                    <div class="course__description-list mt-25 mb-50">
                      <h3>What topics are covered in the course?</h3>
                      <ul>
                        <li *ngFor="let topic of course?.curriculum">
                          <i class="icon_check"></i> {{topic}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end curriculum tag-->

              <!-- start review tag-->
              <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
                <div class="course__review">
                  <div class="course__comment">
                    <h3 class="mb-50">Feedback from Our Students</h3>
                    <div *ngFor="let stud of course?.testimonials" class="row mb-30 bg-gray-100 shadow-sm p-30">
                      <div class="col-xl-2 col-xxl-2 col-lg-2 col-md-2 col-sm-12">
                        <div class="course__comment-thumb float-start">
                          <img src="{{stud.photo}}" alt=""/>
                          <div class="course__comment-info text-center align-items-center mt-20">
                            <h3>{{stud.name}}</h3>
                            <span>{{stud.enrolled}}</span><br/>
                            <div class="course__comment-rating">
                              <ul>
                                <li><a href="#"> <i class="icon_star"></i> </a></li>
                                <li><a href="#"> <i class="icon_star"></i> </a></li>
                                <li><a href="#"> <i class="icon_star"></i> </a></li>
                                <li><a href="#"> <i class="icon_star"></i> </a></li>
                                <li><a href="#"> <i class="icon_star"></i> </a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-10 col-xxl-10 col-lg-10 col-md-10 col-sm-12">
                        <div class="course__description">
                          <p [innerHTML]="stud.review"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end review tag-->
              <app-course-pricing [course]="course"></app-course-pricing>
              <!-- <div class="course__share">
                <h3>Share :</h3>
                <ul>
                  <li><a href="#" class="fb"><i class="social_facebook"></i></a></li>
                  <li><a href="#" class="tw"><i class="social_twitter"></i></a></li>
                </ul>
              </div> -->
            </div>
          </div>
          <!-- Other Courses -->
          <div class="course__related">
            <div class="row">
              <div class="col-xxl-12">
                <div class="section__title-wrapper mb-40">
                  <h3 class="section__title text-primary"> Other
                    <span class="yellow-bg yellow-bg-sm">Courses<img src="assets/img/shape/yellow-bg.png" alt=""/></span>
                  </h3>
                  <p class="text-gray-800">You don't have to face challenges by yourself; you have our support and assistance.</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xxl-12">
                <div class="course__slider swiper-container ">
                  <div class="swiper-wrapper">
                    <swiper class="pb-60" [slidesPerView]="1" [spaceBetween]="30" [loop]="true"
                            [autoplay]="{ delay: 2500, disableOnInteraction: false }"
                            [pagination]="{ clickable: true, el: '.swiper-pagination' }"
                            [breakpoints]="{ '280': { slidesPerView: 1, spaceBetween: 30 },
                        '768': { slidesPerView: 2, spaceBetween: 30 },
                        '1200': { slidesPerView: 2, spaceBetween: 30 } }">
                      <ng-template *ngFor="let _course of getNewList()" swiperSlide>
                        <div class="course__item shadow-sm course__item-3 swiper-slide fix" style="background-color: #D5E8FB;">
                          <div class="course__thumb w-img p-relative fix">
                            <img [routerLink]="['/course-details/'+ _course.uri]" src="{{_course.img}}" alt="{{_course.title}}">
                            <!--<div class="course__tag">
                              <a [routerLink]="['/course-details/'+ _course.id]"
                                 [class]="_course.color">{{_course.category}}</a>
                            </div>-->

                            <div class="course__tag">
                              <a [routerLink]="'/course-details/'+[_course.uri]" >{{_course.category}}</a><!--[class]="course.color"-->
                            </div>
                          </div>
                          <div class="course__content mb-0 pb-0">

                            <div class="course__meta">
                              <div class="course__lesson">
                                <span class="text-black text-16"><i class="icon_book_alt"></i>{{_course.sessions}} Live Sessions </span>
                              </div>
                            </div>

                            <h3 class="course__title"><a [routerLink]="'/course-details/'+[_course.uri]">{{_course.title}}</a></h3>
                            <p class="text-black">{{_course.description}}</p>
                          </div>

                          <div class="course__more d-flex justify-content-end align-items-end mt-0">
                            <div class="course__btn">
                              <a [routerLink]="'/course-details/'+[_course.uri]" class="link-btn">
                                Know Details
                                <i class="arrow_carrot-2right text-18"></i>
                                <i class="arrow_carrot-2right text-18"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </swiper>
                    <!-- Add Pagination -->
                    <div class="swiper-pagination"></div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-3 col-xl-3 col-lg-3">
        <div class="course__sidebar p-relative">
          <div class="course__shape">
            <img class="course-dot _animate_dot_down"  src="assets/img/dotedCircle2.png" alt=""/>
            <!--<img class="course-dot _animate_dot_down"  src="assets/img/course/course-dot.png" alt=""/>-->
          </div>
          <app-related-course [courses]="getNewList()" white_bg="course__sidebar-widget-2 white-bg mb-20">
          </app-related-course>
        </div>
      </div>
    </div>
  </div>

  <!--
  <div class="course__sidebar-widget-2 white-bg mb-20">
    <div class="course__video">
      <div class="course__video-thumb w-img mb-25">
        <img src="assets/img/course/video/course-video.jpg" alt=""/>
        <div data-bs-toggle="modal" data-bs-target="#staticBackdrop" class="course__video-play">
          <button href="https://youtu.be/yJg-Y5byMMw" data-fancybox="" class="play-btn">
            <i class="fas fa-play"></i>
          </button>
        </div>
      </div>
      <div class="course__video-meta mb-25 d-flex align-items-center justify-content-between">
        <div class="course__video-price">
          <h5>{{course.price}}</h5><br/>
          <h5 class="old-price">{{course.oldPrice}}</h5>
        </div>
        <div class="course__video-discount">
          <span>{{course.offer}} OFF</span>
        </div>
      </div>
      <div class="course__video-content mb-35">
        <ul>
          <li class="d-flex align-items-center">
            <div class="course__video-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink"
                   x="0px" viewBox="0 0 24 24" style="enable-background: new 0 0 24 24" xml:space="preserve">
                        <path class="st0" d="M4,19.5C4,18.1,5.1,17,6.5,17H20"/>
                <path class="st0" d="M6.5,2H20v20H6.5C5.1,22,4,20.9,4,19.5v-15C4,3.1,5.1,2,6.5,2z"/>
                      </svg>
            </div>
            <div class="course__video-info">
              <h5><span>Sessions :</span>{{course.sessions}}</h5>
            </div>
          </li>
          <li class="d-flex align-items-center">
            <div class="course__video-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink"
                   x="0px" y="0px" viewBox="0 0 16 16" style="enable-background: new 0 0 16 16"
                   xml:space="preserve">
                        <circle class="st0" cx="8" cy="8" r="6.7"/>
                <polyline class="st0" points="8,4 8,8 10.7,9.3 "/>
                      </svg>
            </div>
            <div class="course__video-info">
              <h5><span>Duration :</span>{{course.duration}}</h5>
            </div>
          </li>
          <li class="d-flex align-items-center">
            <div class="course__video-icon">
              <svg>
                <circle class="st0" cx="8" cy="8" r="6.7"/>
                <line class="st0" x1="1.3" y1="8" x2="14.7" y2="8"/>
                <path class="st0"
                      d="M8,1.3c1.7,1.8,2.6,4.2,2.7,6.7c-0.1,2.5-1,4.8-2.7,6.7C6.3,12.8,5.4,10.5,5.3,8C5.4,5.5,6.3,3.2,8,1.3z"/>
              </svg>
            </div>
            <div class="course__video-info">
              <h5><span>Language :</span>{{course.language}}</h5>
            </div>
          </li>
        </ul>
      </div>
      <div class="course__payment mb-35">
        <h3>Payment:</h3>
        <a href="#">
          <img src="assets/img/course/payment/payment-1.png" alt=""/>
        </a>
      </div>
      <div class="course__enroll-btn">
        <a [routerLink]="['/sign-in']" class="e-btn e-btn-7 w-100">Enroll <i class="far fa-arrow-right"></i></a>
      </div>
    </div>
  </div>

  <div class="course-video-popup">
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <iframe src="https://www.youtube.com/embed/yJg-Y5byMMw" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  -->

</section>
