import {Component, OnInit} from '@angular/core';
import SwiperCore, {Autoplay, EffectFade} from "swiper";


SwiperCore.use([Autoplay, EffectFade]);

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  sliderNavData = [{
    id: 1,
    img: 'assets/img/slider/nav/slider-nav-4.jpg',
    subtitle: "Mastering the Art of Trading",
    description: "A Comprehensive Guide on Market Psychology",
    title: "Mentorship Program",
    bgColor: "orange-bg",
    link: '/course-details/Trading-Psychology'
  }, {
    id: 2,
    img: 'assets/img/slider/nav/slider-nav-1.jpg',
    subtitle: "F&O from Basic to Advance",
    description: "Unlocking Futures & Options from Groundwork to Strategy Mastery",
    title: "Advance F&O",
    bgColor: "blue-bg",
    link: '/course-details/Advance-FnO'
  }, {
    id: 3,
    img: 'assets/img/slider/nav/slider-nav-1.jpg',
    subtitle: "NRT Trading",
    description: "Mentorship to Navigate Trading Strategies with Confidence",
    title: "Mentorship Program",
    bgColor: "pink-bg",
    link: '/course-details/NRT-Trading'
  }, {
    id: 4,
    img: 'assets/img/slider/nav/slider-nav-3.jpg',
    subtitle: "NEoWave Analysis & Trading",
    description: "Ride the Market Waves and Trade them like a Pro",
    title: "Mentorship Program",
    bgColor: "green-bg",
    link: '/course-details/NEoWave-Analysis-Trading'
  }]

  intellxis = [
        "The history and evolution of Wave Theory.",
        "Fundamental concepts of Wave Analysis.",
        "Setting up and customizing Wave Charts.",
        "Interpreting Time, Price, and Complexity on charts.",
        "Identifying and marking Impulsive and Corrective waves.",
        "Detailed study of various Corrective and Impulsive patterns.",
        "Practical exercises on identifying patterns on live charts.",
        "Techniques for forecasting using live charts.",
        "A unique method to trade Wave, which can help you improve your trading skills.",
        "Learn to assess the current market TREND.",
        "Master the art of placing stop loss in different market trends.",
        "Acquire knowledge on trailing stop loss.",
        "Understand market behavior at the top and bottom.",
        "Learn to gauge market Momentum.",
  ]

  constructor() {
  }

  ngOnInit(): void {
  }

}

