<footer>
  <div class="footer__area footer-bg">
    <div [ngClass]="footerPadd ? footerPadd : 'footer__top pt-40' ">
      <div class="container">
        <div class="row pb-30">
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
            <div class="footer__widget">
              <div class="footer__widget-head">
                <div class="footer__logo">
                  <img src="assets/img/logo/logo_white.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xxl-2 col-xl-2 col-lg-3 col-md-2 col-sm-4">
            <div class="footer__widget">
              <!--<div class="footer__widget-head mb-22">
                 <h3 class="footer__widget-title">Intellxis</h3>
              </div>-->
              <div class="footer__widget-body">
                <div class="footer__link text-white">
                  <ul>
                    <li><a [routerLink]="['/']">Home</a></li>
                    <li><a [routerLink]="['/courses']">Courses</a></li>
                    <li><a [routerLink]="['/services']">Services</a></li>
                    <li><a [routerLink]="['/about']">About Us</a></li>
                    <!--<li><a [routerLink]="['/contact']">Contact Us</a></li>-->
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-2 col-sm-4">
            <div class="footer__widget">
              <!--<div class="footer__widget-head mb-22">
                 <h3 class="footer__widget-title">Resources</h3>
              </div>-->
              <div class="footer__widget-body">
                <div class="footer__link text-white">
                  <ul>
                    <li><a [routerLink]="['/faqs']">FAQs</a></li>
                    <li><a [routerLink]="['/forecast']">Forecast</a></li>
                    <li><a [routerLink]="['/podcast']">Podcast</a></li>
                    <li><a [routerLink]="['/contact']">Contact Us</a></li>
                    <!--<li><a [routerLink]="['/blogs']">News & Blogs</a></li>-->
                    <!--<li><a [routerLink]="['/ask-questions']">Ask Questions</a></li>-->
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-2 col-sm-4">
            <div class="footer__widget">
              <!--<div class="footer__widget-head mb-22">
                 <h3 class="footer__widget-title">Resources</h3>
              </div>-->
              <div class="footer__widget-body">
                <div class="footer__link text-white">
                  <ul>
                    <li><a [routerLink]="['/security']">Security</a></li>
                    <li><a [routerLink]="['/disclosure']">Disclosure</a></li>
                    <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                    <li><a [routerLink]="['/terms-of-service']">Terms of Service</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-2">
            <div class="footer__widget">
              <div class="footer__widget-body">
                <div class="footer__social">
                  <ul>
                    <li><a href="https://youtube.com/@intellxis" target="_blank">
                      <img alt="youtube" src="assets/img/social/youtube.svg"/></a>
                    </li>
                    <li><a href="https://t.me/intellxis" target="_blank">
                      <img alt="telegram" src="assets/img/social/telegram.svg"/></a>
                    </li>
                    <li><a href="https://wa.me/919479999887" target="_blank">
                      <img alt="whatsapp" src="assets/img/social/whatsapp.svg"/></a>
                    </li>
                  </ul>
                  <ul class="mt-10">
                    <li><a href="https://facebook.com/intellxis" target="_blank">
                      <img alt="facebook" src="assets/img/social/facebook.svg"/></a></li>
                    <li><a href="https://twitter.com/intellxis" target="_blank">
                      <img alt="twitter" src="assets/img/social/twitter.svg"/></a></li>
                    <li><a href="https://www.instagram.com/intellxis/" target="_blank">
                      <img alt="instagram" src="assets/img/social/instagram.svg"/></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom mt-0">
      <div class="container">
        <div class="row">
          <div class="col-xxl-12">
            <div class="footer__copyright text-center text-white">
              <p>© 2021 Intellxis, All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
