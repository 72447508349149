import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainAreaComponent} from "./main-area/main-area.component";
import {CoursePageComponent} from "./courses/course-page/course-page.component";
import {CourseDetailsComponent} from "./courses/course-details/course-details.component";
import {ServiceMainComponent} from "./services/service-main/service-main.component";
import {AboutUsComponent} from "./common/about-us/about-us.component";
import {SignInComponent} from "./sign-in/sign-in.component";
import {SignUpComponent} from "./sign-up/sign-up.component";
import {ContactUsComponent} from "./common/contact-us/contact-us.component";
import {FaqsMainComponent} from "./faqs/faqs-main/faqs-main.component";
// import {ErrorPageComponent} from "./common/error-page/error-page.component";

// import {BlogDetailsComponent} from "./resourses/blogs/blog-details/blog-details.component";
// import {BlogMainComponent} from "./resourses/blogs/blog-main/blog-main.component";

import {ForecastMainComponent} from "./resourses/forecast/forecast-main/forecast-main.component";
import {PodcastMainComponent} from "./resourses/podcast/podcast-main/podcast-main.component";
import {SecurityComponent} from "./common/security/security.component";
import {DisclosureComponent} from "./common/disclosure/disclosure.component";
import {PrivacyPolicyComponent} from "./common/privacy-policy/privacy-policy.component";
import {TermsOfServicesComponent} from "./common/terms-of-services/terms-of-services.component";
import {ResourceMainComponent} from "./resourses/resource-main/resource-main.component";
import {ForecastingComponent} from "./services/forecasting/forecasting.component";
import {PluginWaveComponent} from "./services/plugin-wave/plugin-wave.component";
import {PluginFnoComponent} from "./services/plugin-fno/plugin-fno.component";
import {OldForecastComponent} from "./resourses/old-forecast/old-forecast.component";
// import {AskMainComponent} from "./resourses/ask-questions/ask-main/ask-main.component";

const routes: Routes = [
  {path: '', component: MainAreaComponent},
  {path: 'courses', component: CoursePageComponent},
  {path: 'course-details/:id', component: CourseDetailsComponent},
  {path: 'services', component: ServiceMainComponent},
  // {path: 'blogs', component: BlogMainComponent},
  // {path: 'blog-details/:id', component: BlogDetailsComponent},
  {path: 'sign-in', component: SignInComponent},
  // {path: 'sign-up', component: SignUpComponent},
  {path: 'about', component: AboutUsComponent},
  {path: 'contact', component: ContactUsComponent},

  {path: 'services', component: ServiceMainComponent},
  {path: 'forecasting', component: ForecastingComponent},
  {path: 'previous-forecasting', component: OldForecastComponent},
  {path: 'plugin-wave', component: PluginWaveComponent},
  {path: 'plugin-trading', component: PluginFnoComponent},

  {path: 'resources', component: ResourceMainComponent},
  {path: 'faqs', component: FaqsMainComponent},
  {path: 'forecast', component: ForecastMainComponent},
  {path: 'podcast', component: PodcastMainComponent},
  // {path: 'ask-questions', component: AskMainComponent},

  {path: 'security', component: SecurityComponent},
  {path: 'disclosure', component: DisclosureComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'terms-of-service', component: TermsOfServicesComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {
}
