import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';

@Injectable()
export class MetaService {
  constructor(private meta: Meta, private title: Title, private route: ActivatedRoute) {
  }

  updateMetaTags(route: string) {
    const metaDescription = this.getMetaDescriptionForRoute(route);
    const title = this.getMetaTitleForRoute(route);
    this.meta.updateTag({name: 'description', content: metaDescription});
    this.title.setTitle(title)
    return metaDescription;
  }

  private getMetaTitleForRoute(url: string): string {
    let title = 'Online Stock Trading in India | Professional Stock Market Courses Online';

    switch (url) {
      case '/course-details/1':
        title = 'Course for Basic of Stock Market - Intellxis | Trade Wisely';
        break;
      case '/course-details/2':
        title = 'Fundamental Analysis Course - Intellxis | Trade Wisely';
        break;
      case '/course-details/3':
        title = 'Advanced F&O Course for Beginners - Intellxis | Trade Wisely';
        break;
      case '/course-details/4':
        title = 'NEoWave Analysis From Basic to Advance Course - Intellxis | Trade Wisely';
        break;
      case '/course-details/5':
        title = 'NRT (F&O) Trading Course - Intellxis | Trade Wisely';
        break;
      case '/course-details/6':
        title = 'Market Psychology Course - Intellxis | Trade Wisely';
        break;

      case '/course-details/Price-Action':
        title = 'Course for Basic of Stock Market - Intellxis | Trade Wisely';
        break;
      case '/course-details/Fundamental-Analysis':
        title = 'Fundamental Analysis Course - Intellxis | Trade Wisely';
        break;
      case '/course-details/Advance-FnO':
        title = 'Advanced F&O Course for Beginners - Intellxis | Trade Wisely';
        break;
      case '/course-details/NEoWave-Analysis-Trading':
        title = 'NEoWave Analysis From Basic to Advance Course - Intellxis | Trade Wisely';
        break;
      case '/course-details/NRT-Trading':
        title = 'NRT (F&O) Trading Course - Intellxis | Trade Wisely';
        break;
      case '/course-details/Trading-Psychology':
        title = 'Market Psychology Course - Intellxis | Trade Wisely';
        break;

      case '/courses':
        title = 'Complete Course List - Intellxis | Trade Wisely';
        break;
      case '/services':
        title = 'Forecasting & Plugin Services - Intellxis | Trade Wisely';
        break;
      case '/about':
        title = 'About US - Intellxis | Trade Wisely';
        break;
      case '/contact':
        title = 'Contact US - Intellxis | Trade Wisely';
        break;
      case '/plugin-fno':
        title = 'F&O Plugin Service - Intellxis | Trade Wisely';
        break;
      case '/plugin-wave':
        title = 'NEoWave Chart Plugin Service - Intellxis | Trade Wisely'
        break;
      case '/forecasting':
        title = 'Forecasting Service - Intellxis | Trade Wisely';
        break;
      case '/resources':
        title = 'Resource - Intellxis | Trade Wisely';
        break;
      case '/faqs':
        title = 'FAQs - Intellxis | Trade Wisely';
        break;
      case '/forecast':
        title = 'Forecast Marking - Intellxis | Trade Wisely';
        break;
      case '/podcast':
        title = 'Podcast - Intellxis | Trade Wisely';
        break;
      case '/security':
        title = 'Security Policy - Intellxis | Trade Wisely';
        break;
      case '/disclosure':
        title = 'Disclosure - Intellxis | Trade Wisely';
        break;
      case '/privacy-policy':
        title = 'Privacy Policy - Intellxis | Trade Wisely';
        break;
      case '/terms-of-service':
        title = 'Term of Services - Intellxis | Trade Wisely';
        break;
    }
    // console.log("Meta Description:",title)
    return title;
  }

  private getMetaDescriptionForRoute(url: string): string {
    let desc = 'Navigate the markets confidently in India with Intellxis. Our Professional Stock Trading and Stock Market Trading Courses offer essential skills for financial excellence.';

    switch (url) {
      case '/course-details/1':
        desc = 'Learn the Basic of Stock market from scratch to advance, our Price Action Course will help you learn the each action of market price movement.';
        break;
      case '/course-details/2': // Fundamental Analysis Course
        desc = 'Invest with confidence using Intellxis. Our Fundamental Analysis course equips you with essential skills in financial statements, industry analysis & valuation for successful investing.';
        break;
      case '/course-details/3': // Advanced F&O Course
        desc = 'Unlock the futures & options (F&O) trading course at Intellxis. From basics to advanced strategies, our expert-led modules ensure a deep understanding. Enroll for personalized guidance!';
        break;
      case '/course-details/4': // Wave Analysis Course
        desc = 'Master Elliott Wave Analysis (NEoWave Analysis) in India at Intellxis! Join our expert-led course for stock market secrets, chart interpretation, and comprehensive understanding. Enroll now!';
        break;
      case '/course-details/5': // F&O Trading Course
        desc = 'Refine your Future and Option (F&O) trading (NRT: Neely River Trading) progress with Intellxis mentorship program. Expert guidance, risk management, and tailored strategies for trading excellence.';
        break;
      case '/course-details/6': // Market Psychology Course
        desc = 'Unleash trading mastery with Intellxis: \'Master the Art of Trading\' program. Decode outcomes, master decision-making, and align your default trading style for success.';
        break;
      case '/courses':
        desc = 'Complete Course List.';
        break;
      case '/services':
        desc = 'All Premium Services.';
        break;
      case '/about':
        desc = 'About Intellxis';
        break;
      case '/contact':
        desc = 'Contact Intellxis';
        break;
      case '/plugin-fno':
        desc = 'NRT Plugin Service';
        break;
      case '/plugin-wave':
        desc = 'Master Advanced Elliott Wave Analysis & NEoWave techniques with Intellxis\'s Trading Course - Decode market patterns & boost your trading strategy Now!'
        break;
      case '/forecasting':
        desc = 'Forecasting Service';
        break;
      case '/resources':
        desc = 'Resource';
        break;
      case '/faqs':
        desc = 'FAQs';
        break;
      case '/forecast':
        desc = 'Forecast Marking';
        break;
      case '/podcast':
        desc = 'Podcast';
        break;
      case '/security':
        desc = 'Security Policy';
        break;
      case '/disclosure':
        desc = 'Disclosure';
        break;
      case '/privacy-policy':
        desc = 'Privacy Policy';
        break;
      case '/terms-of-service':
        desc = 'Term of Services';
        break;
    }
    // console.log("Meta Description:",desc)
    return desc;
  }
}
