import { Component } from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './home-index.component.html',
  styleUrls: ['./home-index.component.scss']
})
export class HomeIndexComponent {

}
