<section class="page__title-area page__title-height page__title-overlay d-flex align-items-center" [ngStyle]="{background:'url('+backImage+')',backgroundRepeat:'no-repeat',backgroundSize:'cover', backgroundPosition:'right'}">
  <div class="container">
    <div class="row">
      <div class="col-xxl-12">
        <div class="page__title-wrapper mt-110">
          <h3 class="page__title mb-25">{{title}}</h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a class="text-25" [routerLink]="['/']">Home</a></li>
              <li class="breadcrumb-item active text-25" aria-current="page">{{subtitle}}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>
