import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-forecast-details',
  templateUrl: './forecast-details.component.html',
  styleUrls: ['./forecast-details.component.scss']
})
export class ForecastDetailsComponent implements OnInit {

  tradingview = [
    {link: 'https://www.tradingview.com/x/GWVfl2Q1/', title: 'Nifty Energy', description: '2.5 Year wave chart', img: 'assets/img/forecast/niftyenergy.png'},
    {link: 'https://www.tradingview.com/x/BZiG8iqf/', title: 'Nifty Metal', description: 'Half Yearly wave chart', img: 'assets/img/forecast/niftymetal.png'},
    {link: 'https://www.tradingview.com/x/P9SCZ8X7/', title: 'Nifty Infra', description: '2.5 Year wave chart', img: 'assets/img/forecast/niftyinfra.png'},
    {link: 'https://www.tradingview.com/x/w3g4trcZ/', title: 'Nifty IT', description: '2.5 Year wave chart', img: 'assets/img/forecast/niftyit.png'},
    {link: 'https://www.tradingview.com/x/sLP1mSPS/', title: 'HDFC Bank', description: 'Monthly wave chart', img: 'assets/img/forecast/hdfcbank.png'},
    {link: 'https://www.tradingview.com/i/rpkvwztf/', title: 'Au Small Finance Bank', description: 'weekly wave chart', img: 'assets/img/forecast/aubank-2.png'},
    {link: 'https://www.tradingview.com/x/mlRI148t/', title: 'Au Small Finance Bank', description: 'weekly wave chart', img: 'assets/img/forecast/aubank.png'},
    {link: 'https://www.tradingview.com/i/gF3k78XZ/', title: 'Nifty IT', description: 'weekly wave chart', img: 'assets/img/forecast/niftyit-2.png'},
    {link: 'https://www.tradingview.com/x/Ft1mj5se/', title: 'Nifty IT', description: 'weekly wave chart', img: 'assets/img/forecast/niftyit-3.png'},
    {link: 'https://www.tradingview.com/x/yei4lWS4/', title: 'Ramco Cements', description: '2.5 Year wave chart', img: 'assets/img/forecast/ramcocement.png'},
    {link: 'https://www.tradingview.com/x/ZY04LuM8/', title: 'SAIL', description: '2.5 Year wave chart', img: 'assets/img/forecast/sail.png'},
    {link: 'https://www.tradingview.com/x/dRxTeOoh/', title: 'Hindustan Copper', description: '2.5 Year wave chart', img: 'assets/img/forecast/hindustancopper.png'},
    {link: 'https://www.tradingview.com/x/rhnMArwz/', title: 'NTPC ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/ntpc.png'},
    {link: 'https://www.tradingview.com/x/5veHBYhG/', title: 'infosys ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/infosys.png'},
    {link: 'https://www.tradingview.com/x/MwvvVynD/', title: 'wipro ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/wipro.png'},
    {link: 'https://www.tradingview.com/x/BoHtvjp0/', title: 'dlf ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/dlf.png'},
    {link: 'https://www.tradingview.com/x/auGDEBk9/', title: 'reliance industries', description: '2.5 Year wave chart', img: 'assets/img/forecast/reliance.png'},
    {link: 'https://www.tradingview.com/x/rtMP251M/', title: 'PVR ltd', description: 'Half Yearly wave chart', img: 'assets/img/forecast/pvr.png'},
    {link: 'https://www.tradingview.com/x/FFzA3NkJ/', title: 'EID Parry', description: '2.5 Year wave chart', img: 'assets/img/forecast/eidparry.png'},
    {link: 'https://www.tradingview.com/x/71L0IRJ1/', title: 'havells india', description: '2.5 Year wave chart', img: 'assets/img/forecast/havells.png'},
    {link: 'https://www.tradingview.com/x/acb3xVM1/', title: 'symphony ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/symphony.png'},
    {link: 'https://www.tradingview.com/x/YwUblYGE/', title: 'kansai nerolac', description: '2.5 Year wave chart', img: 'assets/img/forecast/kansai.png'},
    {link: 'https://www.tradingview.com/x/uTtTeyPB/', title: 'cera sanitaryware', description: '2.5 Year wave chart', img: 'assets/img/forecast/cera.png'},
    {link: 'https://www.tradingview.com/x/bdj6DNZW/', title: 'radico khaitan', description: '2.5 Year wave chart', img: 'assets/img/forecast/radicokhaitan.png'},
    {link: 'https://www.tradingview.com/x/xKlPwCyv/', title: 'balrampur chini', description: '2.5 Year wave chart', img: 'assets/img/forecast/balrampurchini.png'},
    {link: 'https://www.tradingview.com/x/92EgvptL/', title: 'rajesh exports', description: '2.5 Year wave chart', img: 'assets/img/forecast/rajeshexport.png'},
    {link: 'https://www.tradingview.com/x/JkuqzZ4K/', title: 'ifb industries ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/ifbindustries.png'},
    {link: 'https://www.tradingview.com/x/hW3MZPnG/', title: 'j.b. chemicals & Pharma ltd', description: '2.5 Year wave chart' , img: 'assets/img/forecast/.png'},
    {link: 'https://www.tradingview.com/x/T514ciSW/', title: 'Natco pharma ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/natcopharma.png'},
    {link: 'https://www.tradingview.com/x/jWiQFYP6/', title: 'pfizer ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/pfizer.png'},
    {link: 'https://www.tradingview.com/x/A8mDbUP6/', title: 'aarti drugs ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/aartidrugs.png'},
    {link: 'https://www.tradingview.com/x/xp9YLeIi/', title: 'Gujarat Narmada Valley Fertilizers & Chemicals Ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/.png'},
    {link: 'https://www.tradingview.com/x/c94kxtXq/', title: 'Navin fluorine international ltd', description: '2.5 Year wave chart', img: 'assets/img/forecast/navinfluorin.png'},
    {link: 'https://www.tradingview.com/x/fwp8sl08/', title: 'idfc first bank', description: 'half Yearly wave chart', img: 'assets/img/forecast/idfcfirstbank-2.png'},
    {link: 'https://www.tradingview.com/x/bkpoIFGa/', title: 'idfc first bank', description: 'monthly wave chart', img: 'assets/img/forecast/idfcfirstbank-1.png'},
  ];

  constructor(private sanitizer: DomSanitizer) {
  }

  public ngOnInit(): void {
  }

  public getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
