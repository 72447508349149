import { Component } from '@angular/core';

@Component({
  selector: 'app-resource-main',
  templateUrl: './resource-main.component.html',
  styleUrls: ['./resource-main.component.scss']
})
export class ResourceMainComponent {

}
