import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-terms-of-services',
  templateUrl: './terms-of-services.component.html',
  styleUrls: ['./terms-of-services.component.scss']
})
export class TermsOfServicesComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!!window && !!window.scrollTo) {
        let doc = document.querySelector('#termCondition');
        if (doc) doc.scrollIntoView({behavior: 'smooth'});
      }
    }, 500);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth', block: 'start'});
    // el.scrollTo(el.scrollLeft(), el.scrollTop() - 500);
  }

}
