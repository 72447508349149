import { Component } from '@angular/core';

@Component({
  selector: 'app-faqs-area',
  templateUrl: './faqs-area.component.html',
  styleUrls: ['./faqs-area.component.scss']
})
export class FaqsAreaComponent {

}
