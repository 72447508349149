import {Component, OnInit} from '@angular/core';
import {Course, CourseI} from "../../../../shared/constants/course";

@Component({
  selector: 'app-course-page',
  templateUrl: './course-page.component.html',
  styleUrls: ['./course-page.component.scss']
})
export class CoursePageComponent  implements OnInit {

  courses: CourseI[] = [];

  constructor(private _courses: Course) {
    this.courses = this._courses.getCourses();
  }

  ngOnInit(): void {
  }

}
