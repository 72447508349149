import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {ForecastVideos, YoutubeLinks} from "../../../../shared/";
import SwiperCore, {Autoplay, Pagination} from "swiper";
import {left} from "@popperjs/core";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay])

@Component({
  selector: 'app-forecasting-videos',
  templateUrl: './forecasting-videos.component.html',
  styleUrls: ['./forecasting-videos.component.scss']
})
export class ForecastingVideosComponent implements OnInit {
  @Input() bgColor: string | null = "";
  youtube: YoutubeLinks

  constructor(private sanitizer: DomSanitizer, public changeDetectorRef: ChangeDetectorRef, public videoService: ForecastVideos) {
    this.youtube = this.videoService.getVideos();
  }

  ngOnInit(): void {
  }

  public getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  protected readonly left = left;
}
