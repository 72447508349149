<main>
  <app-hero></app-hero>
  <app-course-page-area [courses]="courses"></app-course-page-area>
  <app-service-area></app-service-area>
  <!--app-forecasting-videos bgColor="bg-gray-300"></app-forecasting-videos>-->

  <!--  <app-category></app-category>-->
  <!--  <app-banner-area></app-banner-area>-->
  <!--  <app-courses></app-courses>-->
  <!--  <app-events></app-events>-->
  <!--  <app-pricing></app-pricing>-->
  <!--<app-cta></app-cta>-->
</main>
