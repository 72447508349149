import {Component, OnInit} from '@angular/core';
import {Course, CourseI} from "../../../shared/constants/course";

@Component({
  selector: 'app-main-area',
  templateUrl: './main-area.component.html',
  styleUrls: ['./main-area.component.scss']
})
export class MainAreaComponent implements OnInit {

  courses: CourseI[];

  constructor(private _courses: Course) {
    this.courses = this._courses.getCourses();
  }

  ngOnInit(): void {
  }

}
