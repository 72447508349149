<section class="course__area pb-50">
  <div class="container mt-50">
    <div class="row align-items-center">
      <div class="col-xxl-12 col-xl-12 col-lg-12 text-center">
        <div class="section__title-wrapper">
          <h2 class="section__title text-primary">Free <span class="yellow-bg yellow-bg-big">Resources<img
            src="assets/img/shape/yellow-bg.png" alt=""></span></h2>
          <h3>Improve Your Skills</h3>
          <!--<h4 class="mt-15 font-weight-500 pb-0 mb-0">Embark on Your Learning Journey with Our Comprehensive Basic to
            Advanced and Mentorship Programs</h4>-->
        </div>
      </div>
    </div>
    <div class="row mb-0">
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
        <div class="course__item course__item-2 white-bg transition-3 shadow-lg">
<!--          <div class="course__thumb fix w-img">-->
<!--            <a [routerLink]="['/forecasting']">-->
<!--              <img src="assets/img/course/home-3/course-1.jpg" alt="">-->
<!--            </a>-->
<!--          </div>-->
          <div class="course__content-2">
            <h3 class="course__title-2"><a [routerLink]="['/forecast']">
              NEoWave Marking on Major Indices & Stocks</a></h3>
            <p class="mt-15">
              Our team at Intellxis is committed to timely published forecasting ideas and marking on major indices, sectors and stocks, you can access them all free.
            </p>
            <div class="course__bottom d-sm-flex justify-content-between align-items-center">
              <div class="course__teacher-2 d-flex align-items-center"></div>
              <div class="course__meta">
                <div [routerLink]="['/forecast']" class="course__lesson btn btn-outline-primary border-radius-0">Know
                  More
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
        <div class="course__item course__item-2 white-bg transition-3 shadow-lg">
<!--          <div class="course__thumb fix w-img">-->
<!--            <a [routerLink]="['/plugin-wave']">-->
<!--              <img src="assets/img/course/home-3/course-2.jpg" alt="">-->
<!--            </a>-->
<!--          </div>-->
          <div class="course__content-2">
            <h3 class="course__title-2"><a [routerLink]="['/podcast']">
              Podcast
            </a></h3>
            <p class="mt-15">
              Our team at Intellxis is excited to announce that we are working on creating a podcast series that
              features India's top investors, mutual fund managers, and experienced market professionals.
            </p>
            <div class="course__bottom d-sm-flex justify-content-between align-items-center">
              <div class="course__teacher-2 d-flex align-items-center"></div>
              <div class="course__meta">
                <div [routerLink]="['/podcast']" class="course__lesson btn btn-outline-primary border-radius-0">Know
                  More
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
