import {Component, Input, OnInit} from '@angular/core';
import {CourseI} from "../../../../shared/constants/course";
import {animate} from "@angular/animations";

@Component({
  selector: 'app-course-page-area',
  templateUrl: './course-page-area.component.html',
  styleUrls: ['./course-page-area.component.scss']
})
export class CoursePageAreaComponent implements OnInit {

  @Input() courses: CourseI[] = [];

  constructor() {

  }

  ngOnInit(): void {
  }

  protected readonly animate = animate;
}
