import { Component } from '@angular/core';

@Component({
  selector: 'app-podcast-main',
  templateUrl: './podcast-main.component.html',
  styleUrls: ['./podcast-main.component.scss']
})
export class PodcastMainComponent {

}
