<section class="pt-50 pb-30">
  <div class="container">
    <div class="row">
      <div class="col-xxl-7 col-xl-7 col-lg-6">
        <div class="contact__wrapper">
          <h3>I am new to the stock market, should I join this course?</h3>
          <p class="text-primary text-18">
            Our course start from basics, then we move to the advance. You can join the live classes as many time as you
            want.
          </p>
          <br/>

          <h3>What if I am not satisfy with the course?</h3>
          <p class="text-primary text-18">
            If you are not satisfy with the course and service, please send an email to info@intellxis.com with your
            student
            id and reason, our team will take an immediate action to resolve your issues.
          </p>
          <br/>

          <h3>What is your refund policy?</h3>
          <p class="text-primary text-18">
            Currently we are not offering any refund on any course and services. But after joining our course, you can
            contact
            to our team info@intellxis.com withing a week for refund.
          </p>
          <ul class="text-primary text-18">
            <li><i class="icon_check"></i> Refund can only applicable once the course started.</li>
            <li><i class="icon_check"></i> Refund can only applicable for full payments.</li>
            <li><i class="icon_check"></i> Only 90% of the payment will be refundable.</li>
          </ul>
          <br/>

          <h3>Can I transfer my service or course to other?</h3>
          <p class="text-primary text-18">
            Currently our course and services are non-transferable.
          </p>
          <br/>

          <h3>I can pay fee using EMI?</h3>
          <p class="text-primary text-18">
            Yes you can pay using EMI, just select EMI option when you process for the payment.
          </p>
          <br/>
        </div>
      </div>
      <div class="col-xxl-4 offset-xxl-1 col-xl-4 offset-xl-1 col-lg-5 offset-lg-1 mt-30">
        <div class="contact__info white-bg p-relative z-index-1">
          <div class="contact__shape">
            <img class="contact-shape-1" src="assets/img/contact/contact-shape-1.png" alt="">
            <img class="contact-shape-2" src="assets/img/contact/contact-shape-2.png" alt="">
            <img class="contact-shape-3" src="assets/img/contact/contact-shape-3.png" alt="">
          </div>
          <div class="contact__info-inner white-bg">
            <ul>
              <li>
                <div class="contact__info-item d-flex align-items-center mb-35">
                  <h4 class="text-center">Ask Question</h4>
                </div>
              </li>
              <li>
                <div class="contact__info-item d-flex align-items-start mb-35">
                  <div class="contact__info-icon mr-15">
                    <svg class="mail" viewBox="0 0 24 24">
                      <path class="st0"
                            d="M4,4h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6C2,4.9,2.9,4,4,4z"/>
                      <polyline class="st0" points="22,6 12,13 2,6 "/>
                    </svg>
                  </div>
                  <div class="contact__info-text">
                    <h4>Email us directly</h4>
                    <p><a href="mailto:info@intellxis.com"> info@intellxis.com</a></p>
                    <p><a href="mailto:support@intellxis.com">support@intellxis.com</a></p>
                  </div>
                </div>
              </li>
              <li>
                <div class="contact__info-item d-flex align-items-start mb-35">
                  <div class="contact__info-icon mr-15">
                    <svg class="call" viewBox="0 0 24 24">
                      <path class="st0"
                            d="M22,16.9v3c0,1.1-0.9,2-2,2c-0.1,0-0.1,0-0.2,0c-3.1-0.3-6-1.4-8.6-3.1c-2.4-1.5-4.5-3.6-6-6  c-1.7-2.6-2.7-5.6-3.1-8.7C2,3.1,2.8,2.1,3.9,2C4,2,4.1,2,4.1,2h3c1,0,1.9,0.7,2,1.7c0.1,1,0.4,1.9,0.7,2.8c0.3,0.7,0.1,1.6-0.4,2.1  L8.1,9.9c1.4,2.5,3.5,4.6,6,6l1.3-1.3c0.6-0.5,1.4-0.7,2.1-0.4c0.9,0.3,1.8,0.6,2.8,0.7C21.3,15,22,15.9,22,16.9z"/>
                    </svg>
                  </div>
                  <div class="contact__info-text">
                    <h4>Phone</h4>
                    <p><a href="tel:+919479999887">+(91) 947 99 99 88 7</a></p>
                    <!--<p><a href="tel:+919993585256">+(91) 999 35 85 25 6</a></p>-->
                  </div>
                </div>
              </li>
            </ul>
            <div class="contact__social pl-30 align-self-center">
              <h4>Follow Us</h4>
              <ul>
                <li><a href="https://youtube.com/@intellxis" target="_blank"><img
                  src="assets/img/social/youtube.svg"/></a></li>
                <li><a href="https://t.me/intellxis" target="_blank"><img src="assets/img/social/telegram.svg"/></a>
                </li>
                <li><a href="https://wa.me/919479999887" target="_blank"><img src="assets/img/social/whatsapp.svg"/></a>
                </li>
              </ul>
              <ul class="mt-10">
                <li><a href="https://facebook.com/intellxis" target="_blank"><img
                  src="assets/img/social/facebook.svg"/></a></li>
                <li><a href="https://twitter.com/intellxis" target="_blank"><img
                  src="assets/img/social/twitter.svg"/></a>
                </li>
                <li><a href="https://www.instagram.com/intellxis/" target="_blank"><img
                  src="assets/img/social/instagram.svg"/></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
