<main>
  <app-breadcrumb title="Terms and Conditions" subtitle="Terms and Conditions"
                  backImage="assets/img/page-title/termsandconditions.jpg"></app-breadcrumb>

  <!--  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
       class="icon_check _animate_pulse"></i>
    <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
       class="icon_check _animate_pulse-3"></i>
    <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
       class="icon_check _animate_pulse-5"></i>-->

  <section class="pt-30">
    <div class="container">

      <!--<div class="text-center pb-15">
        <h2>Terms and Conditions</h2>
      </div>-->

      <p class="text-muted text-18 pb-15">
        Please read these Terms of Service carefully before accessing or using our website. By accessing or using any
        part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and
        conditions of this agreement, then you may not access the website or use any services. <b>The terms “we”, “us”
        and “our” refer to Intellxis LLP.</b>
      </p>

      <h3 class="pt-15">General Conditions</h3>
      <ul class="text-muted text-justify text-18">
        <li><i class="icon_check"></i> We reserve the right to refuse service to anyone for any reason at any time.
        </li>
        <li><i class="icon_check"></i> All the fees once paid are non-refundable and non-transferable.</li>
        <li><i class="icon_check"></i> All the courses and services are only for the educational purposes not for the
          trading or investment.
        </li>
        <li><i class="icon_check"></i> Intellxis is not liable for any financial losses or damages that may result from
          the use of the information provided in any course or services.
        </li>
        <li><i class="icon_check"></i> Intellxis is not responsible for any failures or issues related to services
          provided through third-party service providers.
        </li>
      </ul>
      <br/>
      <h3 class="pt-15">Intellxis Courses</h3>
      <p class="text-muted mb-3 text-18 pl-2 pb-10">
        We provide following courses
      </p>
      <ul class="text-muted text-justify text-18 pb-10">
        <li><i class="icon_check"></i> Price Action</li>
        <li><i class="icon_check"></i> NEoWave Analysis & Trading</li>
        <li><i class="icon_check"></i> Fundamental Analysis</li>
        <li><i class="icon_check"></i> Advance F&O</li>
        <li><i class="icon_check"></i> NRT Trading</li>
        <li><i class="icon_check"></i> Commodity Trading</li>
        <li><i class="icon_check"></i> Forex Trading</li>
        <li><i class="icon_check"></i> Market Psychology</li>
      </ul>

      <div class="text-muted mb-3 text-weight-600 text-18 pb-10">
        <b>Termination of Service</b>
      </div>
      <ul class="text-muted text-justify text-18 pb-15">
        <li><i class="icon_check"></i> Intellxis reserves the right to terminate or suspend access to
          the Courses/Services in cases of violation of policies, abusive behavior, or any other
          disruptive actions that may harm the community or the service.
        </li>
      </ul>

      <div class="text-muted mb-3 text-weight-600 text-18 pb-10">
        <b>Terms and Conditions</b>
      </div>

      <ul class="text-muted text-justify text-18 pb-15">
        <li><i class="icon_check"></i> All the courses are intended for personal, non-commercial use only.</li>
        <li><i class="icon_check"></i> All the fees once paid are non-refundable and non-transferable.</li>
        <li><i class="icon_check"></i> All content and intellectual property related to the course, including but not
          limited to the plugins, remain the property of Intellxis.
        </li>
        <li><i class="icon_check"></i> Users must agree to comply with all applicable laws and regulations.</li>
        <li><i class="icon_check"></i> Users are responsible for maintaining the security of their account credentials.
        </li>
        <li><i class="icon_check"></i> Users must provide accurate and complete information when registering for the course.
        </li>
        <li><i class="icon_check"></i> Intellxis reserves the right to modify or discontinue the course at any time without notice.
        </li>
        <li><i class="icon_check"></i> We do not recommend that anyone make any investment or trade decisions based on the information provided in any course.
        </li>
        <li><i class="icon_check"></i> Access to recorded sessions will be available for a limited period, which may vary from time to time.</li>
        <li><i class="icon_check"></i> The availability of recorded sessions is contingent upon third-party service providers. Intellxis is not liable for any failures or issues arising from these external services.</li>
        <li><i class="icon_check"></i> Users assume all risks associated with the use of the course and are solely responsible for any investment
        or trading decisions they make based on the information provided in the course.
        </li>

        <li><i class="icon_check"></i> Intellxis is not liable for any direct, indirect, incidental, special,
          consequential, or punitive damages
          arising out of or in connection with the use of the information provided in any course.
        </li>
        <li><i class="icon_check"></i> Each course is solely for educational purposes and does not prepare individuals
          for obtaining any license, certificate, diploma, or degree in the financial industry.
        </li>
      </ul>

      <h3 class="pt-15">Educational Forecast Services</h3>
      <div class="text-muted mb-3 text-18 pl-2">
        In this service we provide educational forecasting on
      </div>
      <ul class="text-muted text-justify text-18">
        <li><i class="icon_check"></i> Indices and Sector Analysis.</li>
        <li><i class="icon_check"></i> Commodities.</li>
        <li><i class="icon_check"></i> Foreign Indices and Currencies.</li>
      </ul>

      <br/>
      <div class="text-muted mb-3 text-weight-600 text-18 pb-10">
        <b>Subscription & Content Usage Policy</b>
      </div>

      <ul class="text-muted text-justify text-18 pb-10">
        <li><i class="icon_check"></i> Subscribers to Intellxis' Educational Forecasting Services are subject to a
          subscription fee, which grants access to the service for a specified period.
        </li>
        <li><i class="icon_check"></i> Subscribers are prohibited from reproducing, distributing, or sharing content
          outside of their personal use without explicit permission from Intellxis.
        </li>
        <li><i class="icon_check"></i> All content provided as part of the Educational Forecasting Services, including
          reports, analysis, and live sessions, is for personal use and educational purposes only.
        </li>
      </ul>

      <div class="text-muted mb-3 text-weight-600 text-18 pb-10">
        <b>Termination of Service</b>
      </div>
      <ul class="text-muted text-justify text-18 pb-15">
        <li><i class="icon_check"></i> Intellxis reserves the right to terminate or suspend a subscriber's access to
          the Educational Forecasting Services in cases of violation of policies, abusive behavior, or any other
          disruptive actions that may harm the community or the service.
        </li>
      </ul>

      <div class="text-muted mb-3 text-weight-600 text-18 pb-10">
        <b>Terms and Conditions</b>
      </div>

      <ul class="text-muted text-justify text-18 pb-15">
        <li><i class="icon_check"></i> It's important to note that no forecasting service can guarantee accurate
          predictions all the time, as the market can be unpredictable and subject to various factors. However,
          educational forecasting services can provide individuals with a deeper understanding of market dynamics and
          tools to make more informed decisions when it comes to investing and trading.
        </li>
        <li><i class="icon_check"></i> All the fees once paid are non-refundable and non-transferable.</li>
        <li><i class="icon_check"></i> All content and intellectual property related to the Service, including but not
          limited to the plugins, remain the property of Intellxis.
        </li>
        <li><i class="icon_check"></i> Users must agree to comply with all applicable laws and regulations.</li>
        <li><i class="icon_check"></i> Users must provide accurate and complete information when using the Service.
        </li>
        <li><i class="icon_check"></i> Users are responsible for maintaining the security of their account
          credentials.
        </li>
        <li><i class="icon_check"></i> Users may not use the Service for any illegal or unauthorized purpose.</li>
      </ul>

      <div class="text-muted mb-3 text-18 pb-10">
        <b>Trading Risks and Disclaimers</b>
      </div>
      <ul class="text-muted text-justify text-18">
        <li><i class="icon_check"></i> All the forecasting service made available are for educational purposes only.
        </li>
        <li><i class="icon_check"></i> We do not recommend, advocate or solicit the buying, selling or holding of any
          investment.
        </li>
        <li><i class="icon_check"></i> Trading and investing always involves high levels of risk. Any money allocated
          to trading and investing may be lost in its entirety, at any time. You alone are responsible for any trading
          or investing activity that you might undertake.
        </li>
        <li><i class="icon_check"></i> We provide only educational forecasting services not for the trading or
          investment advice, We strongly suggest to do the paper traders only.
        </li>
      </ul>

      <br/>
      <h3 class="pt-15">Trading Services</h3>
      <div class="text-muted mb-3 text-18 pl-2">
        We dont provide any type of investment or trading tips or service/advice.
      </div>
      <br/>

      <h3 class="pt-15">Plugins Service</h3>
      <div class="text-muted mb-3 text-18 pt-5 pb-5">
        We provide following chart plugins for TradingView platform
      </div>

      <h5 class="pt-15 text-muted">NEoWave Chart Plugin</h5>
      <ul class="text-muted text-justify text-18">
        <li><i class="icon_check"></i>
          This plugin will not mark the pattern itself. If you don't know NEoWave then this plugin may not be
          useful for you.
        </li>
        <!--<li><i class="icon_check"></i>
          The plugin will be available with a lifetime offer for 33 years, exclusively for the TradingView platform.
        </li>-->
      </ul>
      <h5 class="pt-15 text-muted">NRT Plugin Package</h5>
      <ul class="text-muted pt-15 text-justify text-18">
        <li>This plugin package will not be useful to the subscriber unless they have completed the NRT course.</li>
        <li>We offer following plugins under NRT plugin package.</li>
      </ul>

      <ul class="text-muted pt-15 text-justify text-18">
        <li><i class="icon_check"></i> NRT Indicator</li>
        <li><i class="icon_check"></i> OBV Indicator</li>
        <li><i class="icon_check"></i> RS Indicator</li>
        <li><i class="icon_check"></i> eMACD Indicator</li>
        <li><i class="icon_check"></i> NRT Screener</li>
        <li><i class="icon_check"></i> Momentum Indicator</li>
      </ul>

      <div class="text-muted mb-3 text-18 pb-10 pt-15">
        <b>Support and Contact Information</b>
      </div>
      <ul class="text-muted text-justify text-18">
        <li><i class="icon_check"></i> For assistance or inquiries related to Intellxis TradingView Chart Plugins,
          please don't hesitate to contact our dedicated customer support team at info@intellxis.com. We're available
          for assistance during our regular business hours, Monday through Friday, from 9:00 AM to 5:00 PM (IST).
        </li>
      </ul>

      <br/>
      <div class="text-muted mb-3 text-18 pb-10">
        <b>Plugin Updates and Versioning</b>
      </div>
      <ul class="text-muted text-justify text-18">
        <li><i class="icon_check"></i> At Intellxis, we continually improve our TradingView Chart Plugins. Updates are
          released periodically to enhance functionality and address any issues. Users will be notified of updates, and
          they can be easily installed within the TradingView platform.
        </li>
      </ul>

      <br/>
      <div class="text-muted mb-3 text-18 pb-10">
        <b>Trading Risks and Disclaimers</b>
      </div>
      <ul class="text-muted text-justify text-18">
        <li><i class="icon_check"></i> Please note that trading involves inherent risks. Our plugins are designed as
          analytical tools and should not be considered financial advice or investment recommendations. Users are solely
          responsible for their trading decisions, and past performance is not indicative of future results. We advise
          users to exercise caution and discretion in their trading activities.
        </li>
      </ul>

      <br/>
      <div class="text-muted mb-3 text-18 pb-10">
        <b>Terms and Conditions and other Policies for Plugin Service</b>
      </div>
      <ul class="text-muted text-justify text-18">
        <li><i class="icon_check"></i> The plugin service is provided "as is" without warranties of any
          kind, either express or implied.
        </li>
        <li><i class="icon_check"></i> Intellxis will not be liable for any damages, including but not limited to
          direct, indirect, incidental, or consequential damages.
        </li>
        <li><i class="icon_check"></i> Intellxis reserves the right to terminate or suspend a
          user's access to the Service at its discretion.
        </li>
        <li><i class="icon_check"></i> All the fees once paid are non-refundable and non-transferable.</li>
      </ul>

      <br/>
      <h4 class="text-gray-800"> While Intellxis strives to provide uninterrupted access to its services, we do not
        guarantee the absence of
        service disruptions due to technical issues, maintenance, or unforeseen circumstances.</h4>
      <br/> <br/>
    </div>
  </section>
</main>

