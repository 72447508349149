<main>
  <app-breadcrumb title="About Us" subtitle="About" backImage="assets/img/page-title/aboutus.jpg"></app-breadcrumb>
  <section class="about__area pt-90 pb-50">
    <div class="container">
      <div class="section__title-wrapper mb-25">
        <h2 class="section__title"><span class="yellow-bg-big">Intellxis <img
          src="assets/img/shape/yellow-bg-2.png" alt=""></span></h2>
        <p class="text-18">
        <p class="text-18">
          At Intellxis, we are passionate about empowering individuals to master the world of finance, trading,
          and investment through the transformative power of knowledge. Our journey began with a vision: to
          demystify complex financial concepts and make them accessible to everyone, from aspiring traders to
          seasoned investors.
        </p>
      </div>

      <h3>Our Mission</h3>
      <p class="text-primary text-18">
        Intellxis‘ mission is to transform 1 million people into intelligent investors by 2025. Together, we can lead in
        a new era of wealth-building for the nation.
      </p>
      <p class="text-18">
        With the goal of improving forecasts, market analysis, and trading results Intellxis is created to fill the
        vacuum that exists in place of financial awareness. The key objectives of Intellxis are to provide a scalable
        learning solution and a Knowledge Centre to enable continuous learning in personal wealth management.
      </p>

    </div>
  </section>
</main>
