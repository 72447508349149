<main>
  <section>  <div class="container mt-50">
    <div class="course__related">
      <div class="row">
        <div class="col-xxl-12">
          <div class="course__slider swiper-container pb-60">
            <div class="swiper-wrapper">
              <swiper class="pb-60" [slidesPerView]="1" [spaceBetween]="30" [loop]="true"
                      [autoplay]="{ delay: 2500, disableOnInteraction: false }"
                      [pagination]="{ clickable: true, el: '.swiper-pagination' }"
                      [breakpoints]="{ '280': { slidesPerView: 1, spaceBetween: 30 },
                        '768': { slidesPerView: 1, spaceBetween: 30 },
                        '1200': { slidesPerView: 1, spaceBetween: 30 } }">
                <ng-template *ngFor="let chart of tradingview" swiperSlide>

                  <div class="section__title-wrapper mb-40 text-center">
                    <h2 class="section__title text-capitalize text-primary"> {{chart.title}}</h2>
                    <h4 class="text-secondary text-capitalize">{{chart.description}}</h4>
                  </div>

                  <div class="course__item shadow-sm course__item-3 swiper-slide white-bg mb-30 fix">
                    <div class="course__thumb w-img p-relative fix">
                      <a [href]="getSafeUrl(chart.link)" target="_blank">
                        <img [src]="chart.img" alt="">
                      </a>
                    </div>
                  </div>
                </ng-template>
              </swiper>
              <!-- Add Pagination -->
              <div class="swiper-pagination"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
</main>
