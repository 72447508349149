<section class="course__area bg-gray">
  <div class="container pt-50 pb-50">
    <div class="row align-items-center">
      <div class="col-xxl-12 col-xl-12 col-lg-12 text-center pb-50">
        <div class="section__title-wrapper">
          <h2 class="section__title text-primary">Our <span class="yellow-bg yellow-bg-big">Service<img
            src="assets/img/shape/yellow-bg.png" alt=""></span></h2>
          <h3>Improve Your Skills</h3>
          <!--<h4 class="mt-15 font-weight-500 pb-0 mb-0">Embark on Your Learning Journey with Our Comprehensive Basic to
            Advanced and Mentorship Programs</h4>-->
        </div>
      </div>
    </div>
    <div class="row mb-0">
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
        <div class="course__item course__item-2 white-bg transition-3 shadow-lg">
<!--          <div class="course__thumb fix w-img">-->
<!--            <a [routerLink]="['/forecasting']">-->
<!--              <img src="assets/img/course/home-3/course-1.jpg" alt="">-->
<!--            </a>-->
<!--          </div>-->
          <div class="course__content-2">
            <h3 class="course__title-2"><a [routerLink]="['/forecasting']">
              Educational Forecasting Service</a></h3>
            <p class="mt-15">
              Subscribing to educational forecasting services can be a great way to improve your forecasting skills and
              enhance your ability to make better predictions in the market. These services can teach you valuable
              techniques and strategies for analyzing market trends and data, and provide you with insights and guidance
              on how to make accurate predictions.
            </p>
            <div class="course__bottom d-sm-flex justify-content-between align-items-center">
              <div class="course__teacher-2 d-flex align-items-center"></div>
              <div class="course__meta">
                <div [routerLink]="['/forecasting']" class="course__lesson btn btn-outline-primary border-radius-0">Know
                  More
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
        <div class="course__item course__item-2 white-bg transition-3 shadow-lg">
<!--          <div class="course__thumb fix w-img">-->
<!--            <a [routerLink]="['/plugin-wave']">-->
<!--              <img src="assets/img/course/home-3/course-2.jpg" alt="">-->
<!--            </a>-->
<!--          </div>-->
          <div class="course__content-2">
            <h3 class="course__title-2"><a [routerLink]="['/plugin-wave']">
              TradingView Chart Plugin
            </a></h3>
            <p class="mt-15">
              An automated wave chart for wave analysis. This is an automated wave chart plotter that help you
              to find the current psychological trend and forecast the next one. By this plugin you can get 5
              second to 5 year wave chart.
            </p>
            <div class="course__bottom d-sm-flex justify-content-between align-items-center">
              <div class="course__teacher-2 d-flex align-items-center"></div>
              <div class="course__meta">
                <div [routerLink]="['/plugin-wave']" class="course__lesson btn btn-outline-primary border-radius-0">Know
                  More
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="row mt-0">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
        <div class="course__item course__item-2 white-bg mb-30 transition-3 shadow-lg mt-30">
          <div class="course__thumb fix w-img shadow-sm border-gray-1">
            <a [routerLink]="['/plugin-fno']">
              <img src="https://www.tradingview.com/x/NY2I33mh/" alt="">
            </a>
          </div>
          <div class="course__content-2">
            <h3 class="course__title-2"><a [routerLink]="['/plugin-fno']">
              Automated Future & Option Trading Plugin for TradingView Platform
            </a></h3>
            <p class="mt-15">
              A Trading system that is designed to be flexible and adaptable to changing market conditions, and it is
              used to trade a variety of asset classes, including stocks, options, futures, and currencies.
            </p>
            <div class="course__bottom d-sm-flex justify-content-between align-items-center">
              <div class="course__teacher-2 d-flex align-items-center"></div>
              <div class="course__meta">
                <div [routerLink]="['/plugin-fno']" class="course__lesson btn btn-outline-primary border-radius-0">Know
                  More
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</section>
