<main>
  <app-breadcrumb title="Privacy Policy" subtitle="Privacy Policy"
                  backImage="assets/img/page-title/privacypolicy.jpg"></app-breadcrumb>

<!--  <i
    style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
    class="fal fa-check _animate_pulse"></i>
  <i
    style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
    class="fal fa-check _animate_pulse-3"></i>
  <i
    style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
    class="fal fa-check _animate_pulse-5"></i>-->

  <section class="pt-30 pb-30">
    <div class="container">
      <!--Online Privacy Notice-->
      <h3 class="border-bottom pb-5">Online Privacy Notice</h3>
      <p class="text-primary text-18">
        You take online privacy seriously, and so does the Intellxis LLP. The terms “we,” “our,” and “us” in this Notice
        refer to Intellxis LLP.
        <br/><br/>
        Our Online Privacy Notice (the “Notice”) describes the information we collect from and about you during your
        visit to our online interfaces (websites and mobile applications) owned or controlled by us (the “Site”). It
        also describes how we use information about you, with whom we share it and how we protect it. This Notice
        applies only to those Intellxis interfaces that link directly to this Notice.<br/><br/>
        It's important to know that our websites may contain links to third party sites not controlled by Intellxis or
        covered by this Notice. We recommend that you check the privacy statements of other sites you visit before
        providing any personal information.<br/><br/>
        Once you become a customer of Intellxis, and once a year while you remain a customer of Intellxis, we will
        provide you with an Intellxis Consumer Privacy Notice that describes our privacy practices and provides you
        choices in the sharing of your information, as required by law.
      </p>
      <!--Personal Information We Collect-->
      <h3 class="border-bottom pb-5">Personal Information We Collect</h3>
      <p class="text-primary text-18">
        We collect Personally Identifiable Information ("PII") about you from the information you provide to us when you
        visit our websites. The information we collect will depend on the Site you visit. This information may include,
        but is not limited to:
      </p>
      <ul class="text-primary text-justify text-18">
        <li><i class="icon_check"></i> Name</li>
        <li><i class="icon_check"></i> Address</li>
        <li><i class="icon_check"></i> Date of birth</li>
        <li><i class="icon_check"></i> Email address</li>
        <li><i class="icon_check"></i> Email referral information</li>
        <li><i class="icon_check"></i> Phone numbers (including mobile)</li>
        <li><i class="icon_check"></i> AADHAAR number in whole or in part</li>
        <li><i class="icon_check"></i> Internet Protocol (IP) Address and/or domain</li>
        <li><i class="icon_check"></i> Geo-location of your computer or mobile device</li>
      </ul>
      <p class="text-primary text-18 mt-10">We use personal information collected online for various functions, which may
        include:</p>
      <ul class="text-primary text-justify text-18">
        <li><i class="icon_check"></i> processing your request for services, products or information</li>
        <li><i class="icon_check"></i> improving our customer service and products</li>
        <li><i class="icon_check"></i> servicing your account</li>
        <li><i class="icon_check"></i> facilitating your participation in online activities.</li>
      </ul>
      <p class="text-primary text-18 mt-10"><b>For example</b>, we may use the information you provide when you complete an
        online application for a
        product or service in order to process and evaluate your application. If you request product information, we
        may use the information you provide to mail you information you request.<br/><br/>
        We may also use the information you provide to contact you regarding your account and to process or collect
        payments. In addition, we may collect and use information for analytic purposes, market research or for
        marketing purposes, as allowed by law.</p>

      <!--How Personal Information Is Shared-->
      <h3 class="border-bottom pb-5">How Personal Information Is Shared</h3>
      <p class="text-primary text-18">We may share the personal information we collect about you with third parties, such as companies performing
        services on our behalf to provide the products or services you have requested.</p>
      <p class="text-primary text-18">As permitted by law, we may also share information collected about you with companies with whom we have
        formal agreements to offer you financial products and services.</p>
      <p class="text-primary text-18">We may share information about you with our affiliates and with nonaffiliates for analysis, market research
        and marketing purposes as allowed by law.</p>
      <p class="text-primary text-18">Finally, we may disclose your personal information as otherwise permitted or required by law.</p>

      <!--Information collected via Cookies-->
      <h3 class="border-bottom pb-5">Information collected via Cookies</h3>
      <p class="text-primary text-18">We use a variety of technologies, including cookies, to collect information about your online/mobile activity
        when you visit our website. Cookies are small text files that a website server stores on your computer or
        device. They’re typically used to remember your account login preferences, monitor website traffic, provide
        customer support like live chat and help us better customize our site for your individual preferences.</p>
      <p class="text-primary text-18">We may also use Flash® objects (sometimes called "Local Shared Objects") as part of our online authentication
        to help us recognize your computer when you come back to our site. We do not use Flash objects for any
        online behavioral advertising purpose.</p>
      <p class="text-primary text-18">You can disable cookies by making the appropriate selection from your browser options to inform you when
        cookies are set or to prevent cookies from being set. However, if you choose to disable or delete cookies,
        you may limit the functionality we can provide when you visit our site.</p>


      <!--Changing or Updating Your Personal Information-->
      <h3 class="border-bottom pb-5">Changing or Updating Your Personal Information</h3>

      <p class="text-primary text-18">
        If you have questions about personal information you provided on an Intellxis website, select the "Contact Us"
        link – usually found in the navigation menu – or log into your Intellxis account and select "My Account" under
        Settings to edit your personal information.
      </p>

      <!--Security Of Your Personal Information-->
      <h3 class="border-bottom pb-5">Security Of Your Personal Information</h3>
      <p class="text-primary text-18">We restrict access to the personal information obtained from our website to only those employees, agents and
        contractors who need it to do their jobs. We maintain administrative, technical, and physical safeguards
        designed to protect your personal information.</p>
      <p class="text-primary text-18">Additionally, companies that provide online services on our behalf are required by contract to protect
        customer information. They are only allowed to use the information they collect for the purpose of providing
        the services that we have contracted to them.</p>

      <!--Public Forums Available On Our Sites And Social Media-->
      <h3 class="border-bottom pb-5">Public Forums Available On Our Sites And Social Media</h3>
      <p class="text-primary text-18">Public forums are available on our Sites, and they may include text, photographs and videos that are
        accessible to the general public. Unless we tell you otherwise, any information you disclose in these areas
        may become public information.</p>

      <p class="text-primary text-18">We also provide experiences through social media sites and applications such as Facebook, Twitter, Instagram,
        LinkedIn, WhatsApp, Telegram and YouTube. Any and all information you share on these Social Media sites and
        applications is visible to any visitor to these sites. Refer to each social media site for their Terms of
        Use and Privacy Policies.</p>
      <p class="text-primary text-18">You should use caution when deciding to disclose personal information in these areas.</p>


      <!--AADHAAR Number Privacy Protection Policy-->
      <h3 class="border-bottom pb-5">AADHAAR Number Privacy Protection Policy</h3>
      <p class="text-primary text-18">
        Intellxis collects AADHAAR numbers as necessary to support state and central legal requirements, to provide
        products or services to you and to meet needs within our Human Resources unit. To support these requirements, we
        restrict the access to AADHAAR numbers only to employees, agents, service providers, and government
        entities that require access to AADHAAR numbers to meet these purposes. We strive to protect AADHAAR numbers
        from unlawful disclosure and keep them confidential by maintaining administrative, technical,
        and physical safeguards that comply with applicable state and central regulations.
      </p>


      <h3 class="border-bottom pb-5">Payment Processing System </h3>
      <p class="text-primary text-18">
        We use Razorpay for processing payments. We/Razorpay do not store your card data on their
        servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-
        DSS) when processing payment. Your purchase transaction data is only used as long as is
        necessary to complete your purchase transaction. After that is complete, your purchase
        transaction information is not saved.
      </p>
      <p class="text-primary text-18">
        Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards
        Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
      </p>
      <p class="text-primary text-18">
        PCI-DSS requirements help ensure the secure handling of credit card information by our store
        and its service providers.
      </p>
      <p class="text-primary text-18">For more insight, you may also want to read terms and conditions of razorpay on
        <a href="https://razorpay.com" target="_blank"><u>https://razorpay.com</u></a>
      </p>
      <!--Changes to this Notice-->
      <h3  class="border-bottom pb-5 mt-10">Changes to this Notice</h3>
      <p class="text-primary text-18">
        This Notice is effective January 1st, 2021. We reserve the right to alter this Notice at our discretion. Any
        change to this Notice will be posted on our site in a timely manner, so please review it frequently.
      </p>
    </div>
  </section>
</main>
