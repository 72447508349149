import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {ForecastingI, ForecastingService} from "../../../../shared/constants/forecasting";
import SwiperCore, {Autoplay, Pagination} from "swiper";
// install Swiper modules
SwiperCore.use([Pagination, Autoplay])
@Component({
  selector: 'app-forecasting',
  templateUrl: './forecasting.component.html',
  styleUrls: ['./forecasting.component.scss']
})
export class ForecastingComponent implements OnInit {
  youtube = {
    broadcast: [
      'Cbgx7q1h__0',
      'iGl3TWL_jFc',
      '_zi1vqAMrAE',
      'n3J0I1X0S2E',
      'WouOnXsSY2U',
      'QJJd31JOfY4',
      'qdW7FhJ30p8',
      'MrpqZgamYtY',
      'CmW7MWk_DHw',
      'cIMs2XIKGeE',
      '5KGmiFlMeJA',
      'ngvd56xgqNA',
      'XRzXDrKjCT8',
      '8Y5PPBl8gF4',
      '_OAqClBM7Tk',
      'JruC0NW1nhY',
      'iZeg2grtfpM',
      'yEjGoVjEl7I',
      'TLwZhe0-2RI',
      // 'XRzXDrKjCT8',
      // 'ngvd56xgqNA',
      // '5KGmiFlMeJA',
    ],
    indices: [
      'L5f8ph0nxmM',
      'YFuhJppnD78',
      'tsxSb8V7kk8',
      'IXv_g7LJ_4w',
      '9zn1hNZnU0s'
    ],
    stocks: [
      'DWZWHDQ2n90'
    ],
  };

  forecasting: ForecastingI[] = [];

  constructor(private sanitizer: DomSanitizer, public changeDetectorRef: ChangeDetectorRef, public _forecasting: ForecastingService) {
    this.forecasting = this._forecasting.getForecasting();
  }

  ngOnInit(): void {
  }

  public getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
