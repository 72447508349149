<main>
  <section class="event__area">
    <div class="container mt-50 mb-50">
      <div class="row align-items-center">
        <div class="col-xxl-12 col-xl-12 col-lg-12 text-center">
          <p class="font-weight-300 text-18 text-align-justify text-secondary pb-0 mb-0 ">
            Our team at Intellxis is excited to announce that we are working on creating a podcast series that features
            India's top investors, mutual fund managers, and experienced market professionals. The podcast will be a
            platform where our guests can share their valuable insights, knowledge, and experiences to help our
            listeners gain a better understanding of the stock market and related investment opportunities.
          </p>
          <p class="mt-15 text-18 font-weight-300 text-align-justify text-secondary pb-0 mb-0 ">
            Through our podcast series, we aim to provide valuable insights and advice to help our listeners make
            informed decisions while investing in the stock market. Our guests will share their experiences and discuss
            various aspects of the market, such as fundamental and technical analysis, portfolio management, risk
            management, and much more.
          </p>
          <p class="mt-15 text-18 font-weight-300 text-align-justify text-secondary pb-0 mb-0 ">
            Stay tuned for updates on our podcast series, where we will be featuring some of the most knowledgeable and
            experienced professionals in the Indian stock market.
          </p>
        </div>
      </div>
    </div>
  </section>
</main>
