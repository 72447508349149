<main>
  <app-breadcrumb title="Disclosure" subtitle="Disclosure"
                  backImage="assets/img/page-title/disclosure.jpg"></app-breadcrumb>

<!--  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
     class="fal fa-check _animate_pulse"></i>
  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
     class="fal fa-check _animate_pulse-3"></i>
  <i style="z-index: 1024; position: absolute; top: 240px; border-radius: 100%; right:360px;color: transparent;background-color: transparent"
     class="fal fa-check _animate_pulse-5"></i>-->

  <section class="pt-30 pb-30">
    <div class="container">

      <p class="text-primary text-18">
        You agree to be bound by subsequent revisions and agree to review the Disclosures periodically for changes.
        The most updated version of these documents will always be available for your review on this page,
        accessible by clicking on the "Disclosures" link that appears at the bottom of the pages on the website.
        <b class="text-secondary text-18">The terms “we,” “our,” and “us” in this page refer to Intellxis LLP.</b>
      </p>

      <!-- Intellxis Courses -->
      <h3 class="pt-15">Intellxis Educational Services</h3>
      <p class="text-primary mb-3 text-18 pl-2 pb-10">
        In this service we provide detailed recommendations on
      </p>
      <ul class="text-primary text-justify text-18">
        <li><i class="icon_check"></i> Price Action</li>
        <li><i class="icon_check"></i> Wave Analysis & Trading</li>
        <li><i class="icon_check"></i> Fundamental Analysis</li>
        <li><i class="icon_check"></i> Advance F&O</li>
        <li><i class="icon_check"></i> F&O Trading</li>
        <li><i class="icon_check"></i> Commodity Trading</li>
        <li><i class="icon_check"></i> Market Psychology</li>
        <li><i class="icon_check"></i> Complete Package</li>
      </ul>
      <br/>
      <p class="text-primary text-18">
        All the courses and training made available are for educational purposes only. We do not recommend, advocate or
        solicit the buying, selling or holding of any investment, the use of any investment choosing methodology, the
        use of any particular financial tools or particular trading platform, or the use of any particular financial
        planner, advisor or broker dealer. Trading and investing always involves high levels of risk. Any money
        allocated to trading and investing may be lost in its entirety, at any time. You alone are responsible for any
        trading or investing activity that you might undertake.
      </p>
      <p class="text-primary text-18">
        All the above courses and training programs are based on our experience, research and information gathered
        from different books, media, and internet space, etc. Intellxis do not accept any responsibility or
        liability for the accuracy, content completeness, legality or reliability of the information contained in
        these courses and training programs.
      </p>
      <p class="text-primary text-18">
        All the above courses and training programs are solely for the educational purposes and are not preparation
        to get any license, certificate, diploma or degree in the financial industry.
      </p>
      <p class="text-primary text-18">
        All the above courses and training programs are designed to get information related to investment and
        trading in stock market, whereas we do not recommend you to do any investment or trading based on the
        information provided in these courses and training programs. Please consult with your financial advisor
        before taking any investment or trading decision. We is not responsible for any type of profit or
        loss to you.
      </p>
      <!-- Intellxis Forecast Service -->
      <h3 class="pt-15">Intellxis Forecast Services</h3>

      <p class="text-primary text-18">
        The past performance of a market forecast does not guarantee the future results or accuracy. Any forecasting
        of security, commodity, sector or market can never be used as a trading purposes.
      </p>
      <p class="text-primary text-18">
        Intellxis provides forecasting services for the educational purposes only. We never guarantee for the
        accuracy of our forecasting services.
      </p>

      <p class="text-primary text-18">
        Our forecasting services provides the information about current and next trend of the security, commodity,
        sector or market. A trend always have ups and downs, sometime violent moves, which can never be used
        for the trading purposes, we do not recommend you to do any investment or trading based on the information
        provided in our forecasting services. Please consult with your financial advisor before taking any
        investment or trading decision. Intellxis is not responsible for any type of direct or indirect profit or
        loss to you.
      </p>

      <!-- Intellxis Software & Plugins -->
      <h3 class="pt-15">Intellxis Software & Plugins</h3>
      <p class="text-primary text-18">
        Intellxis provides varies plugins and software to learn the market trend and behaviour. Intellxis never provides
        the guaranty of accuracy, completeness, or performance of any software or plugin. The accuracy and performance
        of any plugin or software system depends on varies factors. An user should understand these and additional risks
        before using them.
      </p>

      <h3 class="pt-15">Refund Policy</h3>
      <p class="text-primary text-18">
        All the fees once paid are non-refundable and non-transferable.
      </p>

      <h3 class="pt-15">Terms of Use</h3>
      <p class="text-primary text-18">You cannot publish or share any content or material or any part of intellxis'
        courses or services to anyone
        in any form. It will be punishable and illegal to share or publish any content or part of the content of any
        course, or service.
      </p>
      <p class="text-primary text-18"><b>Intellxis</b> reserve all the right to cancel your services any time without
        any prior
        notification, if any illegal action found.
      </p>
      <p class="text-primary text-18"><b>Intellxis</b> reserve the right to disclose any information as necessary.</p>
    </div>
  </section>

</main>
